import {
    QUESTION_SUBMIT_REQUEST,
    QUESTION_SUBMIT_SUCCESS,
    QUESTION_SUBMIT_FAIL,
    QUESTION_GET_REQUEST,
    QUESTION_GET_SUCCESS,
    QUESTION_GET_FAIL
} from "../actions/types";


const defaultProps = {
    loading: false,
    questions: []
};


const taskReducer = (state = [], action) => {
    switch (action.type) {
        case QUESTION_SUBMIT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case QUESTION_SUBMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case QUESTION_SUBMIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };


        case QUESTION_GET_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case QUESTION_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                questions: action.data,
            };
        case QUESTION_GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state;
    }
}
export default taskReducer;
