import {
    ADD_USER_FAIL,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS, GET_ANSWERS_FAIL, GET_ANSWERS_REQUEST, GET_ANSWERS_SUCCESS, GET_INVITES_FAIL,
    GET_INVITES_REQUEST,
    GET_INVITES_SUCCESS,
    INVITE_USER_FAIL,
    INVITE_USER_REQUEST,
    INVITE_USER_SUCCESS,
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    SIGNUP_FAIL,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS, SUBMIT_ANSWER_FAIL, SUBMIT_ANSWER_REQUEST, SUBMIT_ANSWER_SUCCESS,
    USERS_FAIL,
    USERS_REQUEST,
    USERS_SUCCESS
} from "../actions/types";

const defaultProps = {
    loading: false,
    answerId: null,
    answers: []
};

const answerReducer = (state = defaultProps, action) => {
    switch (action.type) {
        case SUBMIT_ANSWER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SUBMIT_ANSWER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                answerId: action.data.answer_id,
            };
        case SUBMIT_ANSWER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };


        case GET_ANSWERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_ANSWERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                answers: action.data,
            };
        case GET_ANSWERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state;
    }
}
export default answerReducer;
