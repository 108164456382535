import React, { Component } from "react";
import "./VideoUpload.scss";
import FilePreview from "./FilePreview";
import { NotificationManager } from "react-notifications";
const styles = {
  inputWrapper: "input-wrapper",
  inputCover: "input-cover",
  helpText: "help-text",
  fileName: "file-name",
  fileNameStretch: "file-name spacer",
  fileExt: "file-ext",
  fileDrag: "file-drag",
  input: "input",
  loader: "loader",
  disabled: "disabled",
  loading: "loading",
  loaderItem: "loader-item",
  spacer: "spacer",
  button: "button",
  hover: "hover",
  imagePreview: "image-preview",
  preview: "preview",
  previewItem: "preview-item",
  previews: "previews",
};

const getExtFromType = (type) => {
  const parts = type.split("/");
  return parts[parts.length - 1];
};
const getExtFromName = (name) => {
  const parts = name.split(".");
  return parts[parts.length - 1];
};

class VideoUpload extends Component {
  state = {
    fileList: [],
  };

  handleDragOver = (e) => {
    if ("preventDefault" in e) {
      e.stopPropagation();
      e.preventDefault();
    }
    let hoverState = null;
    if (e.type === "dragover") {
      hoverState = styles.hover;
    } else {
      hoverState = null;
    }

    this.setState({
      hoverState,
    });
  };

  handleFileSelect = (e) => {
    this.handleDragOver(e);
    const files = e.target.files || e.dataTransfer.files;
    const fileList = Object.keys(files).map((file) => files[file]);
    this.setState({
      fileList,
    });
  };

  removeItem = (index) => {
    const fileList = this.state.fileList;
    fileList.splice(index, 1);
    this.setState({
      fileList,
    });
  };

  removeFile = (file) => {
    const fileList = this.state.fileList;
    const index = fileList.indexOf(file);
    this.removeItem(index);
  };

  uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const fileList = this.state.fileList;
      const index = fileList.indexOf(file);
      let fileExt = file.type.includes("video/");
      console.log(file);
      if (!fileExt) {
        NotificationManager.error(`File type not allowed`);
        return null;
      }
      fileList[index].loading = true;
      this.setState({ fileList });
      if (typeof file === "file" || !("size" in file)) {
        return reject(new Error("No file size"));
      }

      this.props
        .onUpload(file)
        .then((data) => {
          fileList[index].loading = false;
          this.setState({ fileList });
          resolve(data);
        })
        .catch((error) => {
          fileList[index].loading = false;
          this.setState({ fileList });
          reject(error);
        });
    });
  };

  previews = () => {
    return this.state.fileList.map((file, index) => {
      const removeItem = () => {
        this.removeItem(index);
      };
      const uploadFile = () => {
        this.uploadFile(file).then(() => {
          this.removeFile(file);
        });
      };
      return (
        <FilePreview
          key={index}
          data={file}
          onRemove={removeItem}
          onUpload={uploadFile}
        />
      );
    });
  };

  uploadFiles = () => {
    this.state.fileList.forEach((file) => {
      this.uploadFile(file).then(() => {
        this.removeFile(file);
      });
    });
  };

  selectFile = (e) => {
    e.preventDefault();
    this.input.click(e);
  };

  render() {
    const { maxSize, name, multiple, label } = this.props;

    const dragClasses = [styles.fileDrag, this.state.hoverState]
      .join(" ")
      .trim();
    let fileExt = null;
    if (this.state.fileList.length === 1) {
      if (this.state.fileList[0].type) {
        fileExt = `.${getExtFromType(this.state.fileList[0].type)}`;
      } else {
        fileExt = `.${getExtFromName(this.state.fileList[0].name)}`;
      }
    } else {
      fileExt = null;
    }
    let extTail = null;
    if (fileExt) {
    } else {
      extTail = null;
    }

    let fileNames = null;
    if (this.state.fileList.length > 1) {
      fileNames = `${this.state.fileList.length} Files`;
    } else if (this.state.fileList.length === 1) {
      fileNames = this.state.fileList[0].name.replace(fileExt, "");
    } else {
      fileNames = "No file chosen";
    }

    return (
      <div className={"video-upload"}>
        <input type="hidden" name={`${name}:maxSize`} value={maxSize} />
        <div>
          <label>
            <span>{label}</span>
            <div
              className={dragClasses}
              onDragOver={this.handleDragOver}
              onDragLeave={this.handleDragOver}
              onDrop={this.handleFileSelect}
            >
              <div className={styles.inputWrapper}>
                <input
                  type="file"
                  tabIndex="-1"
                  ref={(x) => (this.input = x)}
                  className={styles.input}
                  name={name}
                  multiple={multiple}
                  onChange={this.handleFileSelect}
                />
                <div className={styles.inputCover}>
                  <button
                    className={`${styles.button} invite-btn-class ubuntu-green`}
                    type="button"
                    onClick={this.selectFile}
                  >
                    Choose File
                  </button>
                  <span className={styles.fileName}>{fileNames}</span>
                </div>
              </div>
              <span className={styles.helpText}>or drop files here</span>
            </div>
          </label>
          <br />

          {multiple && (
            <button
              className={styles.button}
              type="button"
              onClick={this.uploadFiles}
            >
              Upload All
            </button>
          )}

          <div className={styles.previews}>{this.previews()}</div>
        </div>
      </div>
    );
  }
}

export default VideoUpload;
