import React from "react";
import Grid from "@material-ui/core/Grid";
import "./SoMuchMore.css";
import InfoCard from "../InfoCards/InfoCard";

const Icons = [
  {
    img: "https://www.marcopolo.me/images/home/so-much-more/app-of-the-day.svg",
    title: "iOS App Of The Day",
    text: "Featured App of the Day in Apple's She Creates Series",
  },
  {
    img: "https://www.marcopolo.me/images/home/so-much-more/top-10.svg",
    title: "Top 10",
    text: "#8 in Social Networking",
  },
  {
    img:
      "https://www.marcopolo.me/images/home/so-much-more/top-in-the-world.svg",
    title: "Top App in The World",
    text: "#21 Most Downloaded in March 2020",
  },
  {
    img: "https://www.marcopolo.me/images/home/so-much-more/rating.svg",
    title: "iOS App Of The Day",
    text: "Featured App of the Day in Apple's She Creates Series",
  },
  {
    img: "https://www.marcopolo.me/images/home/so-much-more/billion-polos.svg",
    title: "iOS App Of The Day",
    text: "Featured App of the Day in Apple's She Creates Series",
  },
  {
    img: "https://www.marcopolo.me/images/home/so-much-more/all-together.svg",
    title: "iOS App Of The Day",
    text: "Featured App of the Day in Apple's She Creates Series",
  },
];

function SoMuchMore() {
  return (
    <div className="soMuchMore">
      <div className="soMuchMore__container">
        <Grid container>
          <Grid item xs={12} lg={12}>
            <div className="soMuchMore__info">
              <h2 className="soMuchMore__info__h2">And so much more…</h2>
            </div>
          </Grid>
          <Grid container item xs={12} lg={12}>
            {Icons.map((item) => (
              <Grid item xs={12} lg={4} sm={4} className="p-3">
                <InfoCard img={item.img} title={item.title} text={item.text} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SoMuchMore;
