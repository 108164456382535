import React, { useState, useEffect } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { updateCMS } from "../../../src/actions/index";
import BackspaceIcon from "@material-ui/icons/Backspace";
export default function Contentcomponent({ inputs, url, data, update }) {
  const [loading, set_loading] = useState(false);
  const [obj, set_obj] = useState({});
  const [input, set_input] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();

    let submit_obj = {
      data: input,
      content_id: "support",
    };
    console.log(submit_obj);
    updateCMS(submit_obj)
      .then((r) => {
        console.log(r);
        update();
      })
      .catch((e) => alert("an error occured"));
  };

  useEffect(() => {
    set_input(inputs ? inputs : []);
  }, [inputs]);

  const addFaq = () => {
    let ix = (input.length + 2) / 2;
    console.log("ix", ix);
    let newArr = [...input];
    newArr.push({
      name: `question_${ix}`,
      placeholder: `Question ${ix}`,
      ix: ix - 1,
    });
    newArr.push({
      name: `answer_${ix}`,
      placeholder: `Answer ${ix}`,
      ix: ix - 1,
    });

    set_input(newArr);
  };
  const handleChange = (name, value) => {
    let new_input = [...input];
    new_input.map((item) => {
      if (item.name === name) {
        item.val = value;
      }
    });
    console.log(input);
    set_input(input);
  };

  const deleteInput = (ix) => {
    let new_input = [...input];
    let deleted = new_input.filter((item) => item.ix !== ix);
    set_input(deleted);
  };
  console.log(input);
  console.log(inputs);
  return (
    <Paper elevation={3} className="mt-4 mb-8">
      <Grid
        container
        direction="row"
        justify="center"
        className="container"
        spacing={3}
      >
        <Grid container lg={6} alignItems="center">
          <img src={url} className="fullw"></img>
        </Grid>
        <Grid item lg={6} className="pl-4 pt-5 pb-1 fullw">
          <div>
            {input?.map((item, index) => (
              <div className="form-group">
                <h6 style={{ color: "red" }}>{item.placeholder} </h6>
                <div className="row-container">
                  <input
                    type="text"
                    value={item.val}
                    onChange={(e) => handleChange(item.name, e.target.value)}
                    name={item.name}
                    style={{ width: "80%", marginRight: 10 }}
                    className="form-control"
                    required
                    autoFocus
                  />
                  {input.length - 2 == index && (
                    <BackspaceIcon
                      className="needHover"
                      onClick={() => deleteInput(item.ix)}
                    ></BackspaceIcon>
                  )}
                </div>
              </div>
            ))}
          </div>
          <Button
            onClick={addFaq}
            className="fullw mb-2"
            variant="contained"
            color="secondary"
          >
            add new question
          </Button>
          <Button
            onClick={handleSubmit}
            className="fullw mb-2 ubuntu-blue"
            variant="contained"
            disabled={loading}
          >
            {loading ? "Changing..." : "Change content"}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
