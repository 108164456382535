import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import "./Footer.css";
function Footer({ data }) {
  const [texts, set_texts] = useState({});
  useEffect(() => {
    set_texts(data ? data.data : {});
    
  }, [data]);
  
  return (
    <div className="footer">
      <div className="footer__container">
        <Grid container>
          <Grid item xs={12} lg={8} sm={12} md={8}>
            <div className="footer__container_head">
              <div className="footer__container_top">
                <a className="text_white" href={`${texts.link_1}`}>
                  <p className="foooter_p">{texts.title_1}</p>
                </a>
                <a className="text_white" href={`${texts.link_2}`}>
                  <p className="foooter_p">{texts.title_2}</p>
                </a>
              </div>
              <div className="footer__container_bottom">
                <a className="text_white" href={`${texts.link_3}`}>
                  <span className="foooter_span">{texts.title_3}</span>
                </a>
                <a className="text_white" href={`${texts.link_4}`}>
                  <span className="foooter_span">{texts.title_4}</span>
                </a>
                <a className="text_white" href={`${texts.link_5}`}>
                  <span className="foooter_span">{texts.title_5}</span>
                </a>
                <a className="text_white" href={`${texts.link_6}`}>
                  <span className="foooter_span">{texts.title_6}</span>
                </a>
                <a className="text_white" href={`${texts.link_7}`}>
                  <span className="foooter_span">{texts.title_7}</span>
                </a>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            sm={12}
            md={4}
            direction="row"
            justify="center"
            alignItems="center"
          ></Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Footer;
