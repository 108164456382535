export const UserTypes = [
    {
        title: 'Ask Questions',
        value: 'user_a'
    },
    {
        title: 'Answer Questions',
        value: 'user_b'
    }
];
//unique string
export const userBIdentityForInviteSignin = '649as380964ds04733w210fa030';

export const getCurrentUrl = (location) => {
    var url = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    return url;
}

export const uniqueKey = () => {
    const timestamp = new Date().valueOf();
    const stringUniq = Math.floor(Math.random() * 10) + '' + timestamp + '' + Math.floor(Math.random() * 10);
    return parseInt(stringUniq);
}


export const findObj = function (list, key, value, defaultIndex = 0) {
    let resultData = list[defaultIndex];
    list.map((data) => {
        if (data[key] == value) {
            resultData = data;
            return;
        }
    });
    return resultData;
};
