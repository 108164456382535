import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import "./SecurityPage.css";
function SecurityPage({ title, dataTexts, dataCard }) {
  const [carts, set_carts] = useState({});
  const [texts, set_texts] = useState({});

  useEffect(() => {
    set_texts(dataTexts ? dataTexts.data : {});
    set_carts(dataCard ? dataCard.data : {});
  }, [dataCard, dataTexts]);
  return (
    <div className="securityPage mt-6">
      <div className="securityPage__container">
        <div
          className="securityPage__container__top"
          style={{
            backgroundColor: "#3239ab",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-center white ">{title}</h1>
        </div>
        <div className="securityPage__container__bottom">
          <div className="securityPage__container__bottom__container">
            <div className="securityPage__container__bottom__container__inner">
              <div className="securityPage__container__bottom__container__inner__texts">
                <div className="securityPage__container__bottom__container__inner__title">
                  <h2 className="securityPage__container__bottom__container__inner__title__h2">
                    {texts.title_1}
                  </h2>
                </div>
                <p className="securityPage__container__bottom__container__inner__p">
                  {texts.content_1}
                </p>
                <div className="securityPage__container__bottom__container__inner__title">
                  <h2 className="securityPage__container__bottom__container__inner__title__h2">
                    {texts.title_2}
                  </h2>
                </div>
                <p className="securityPage__container__bottom__container__inner__p">
                  {texts.content_2}
                </p>
                <div className="securityPage__container__bottom__container__inner__title">
                  <h2 className="securityPage__container__bottom__container__inner__title__h2">
                    {texts.title_3}
                  </h2>
                </div>
                <p className="securityPage__container__bottom__container__inner__p">
                  {texts.content_3}
                </p>
                <div className="securityPage__container__bottom__container__inner__title">
                  <h2 className="securityPage__container__bottom__container__inner__title__h2">
                    {texts.title_4}
                  </h2>
                </div>
                <p className="securityPage__container__bottom__container__inner__p">
                  {texts.content_4}
                </p>
              </div>
              <div className="securityPage__container__bottom__container__inner__cards">
                <Grid container>
                  <Grid
                    container
                    xs={12}
                    md={6}
                    lg={6}
                    justify="center"
                    alignItems="center"
                    className="p-5 text-align"
                  >
                    <div className="p-4">
                      <img
                        className="img__size"
                        src="https://img.icons8.com/color/344/password.png"
                      />
                    </div>
                    <h4>{carts.title_1}</h4>
                    <p>{carts.content_1}</p>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    md={6}
                    lg={6}
                    justify="center"
                    alignItems="center"
                    className="p-5 text-align"
                  >
                    <div className="p-4">
                      <img
                        className="img__size"
                        src="https://www.flaticon.com/svg/static/icons/svg/2771/2771983.svg"
                      />
                    </div>
                    <h4>{carts.title_2}</h4>
                    <p>{carts.content_2}</p>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    md={6}
                    lg={6}
                    justify="center"
                    alignItems="center"
                    className="p-5 text-align"
                  >
                    <div className="p-4">
                      <img
                        className="img__size"
                        src="https://www.flaticon.com/svg/static/icons/svg/1422/1422825.svg"
                      />
                    </div>
                    <h4>{carts.title_3}</h4>
                    <p>{carts.content_3}</p>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    md={6}
                    lg={6}
                    justify="center"
                    alignItems="center"
                    className="p-5 text-align"
                  >
                    <div className="p-4">
                      <img
                        className="img__size"
                        src="https://www.flaticon.com/svg/static/icons/svg/1035/1035311.svg"
                      />
                    </div>
                    <h4>{carts.title_4}</h4>
                    <p>{carts.content_4}</p>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityPage;
