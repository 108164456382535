import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAIL,
  ADD_TASK,
  DELETE_TASK,
  FETCH_TASK,
  UPDATE_TASK,
  UPDATE_TASK_TIME,
  UPDATE_TASK_TIMER_STATUS,
  FETCH_REPORT,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  QUESTION_SUBMIT_REQUEST,
  QUESTION_SUBMIT_SUCCESS,
  QUESTION_SUBMIT_FAIL,
  QUESTION_GET_REQUEST,
  QUESTION_GET_SUCCESS,
  QUESTION_GET_FAIL,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAIL,
  GET_INVITES_REQUEST,
  GET_INVITES_SUCCESS,
  GET_INVITES_FAIL,
  SUBMIT_ANSWER_REQUEST,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_FAIL,
  GET_ANSWERS_REQUEST,
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAIL,
  SOCIAL_LOGIN_SUCCESS,
  SOCIAL_LOGIN_FAIL,
  SOCIAL_LOGIN_REQUEST,
  SWITCH_USER_ACCOUNT_REQUEST,
  SWITCH_USER_ACCOUNT_SUCCESS,
  SWITCH_USER_ACCOUNT_FAIL,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_CHANGE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  VERIFY_PHONE_REQUEST,
  VERIFY_PHONE_SUCCESS,
} from "./types";
import axios from "axios";
import md5 from "md5";
import { API } from "aws-amplify";
import { uniqueKey } from "../helpers/util";
import { NotificationManager } from "react-notifications";

/**
 * API Url: url of the server instance.
 */
export const apiUrl =
  "https://bfnoeaxnwa.execute-api.us-east-2.amazonaws.com/dev";

const extractError = (error) => {
  if (error.response && error.response.data) {
    if (typeof error.response.data.error == "object") {
      return error.response.data.error.message;
    } else {
      return error.response.data.error;
    }
  } else {
    return error.message;
  }
};
export const addtokens = (payload) => ({
  type: "LOGIN_SUCCESS",
  data: payload,
});
export const updateUser = () => ({
  type: "UPDATE_USER",
});
const request = (type) => {
  return { type };
};

export const notificationAction = (payload) => ({
  type: "SET_NOTIFICATIONS",
  payload: payload,
});

const success = (type, data = null) => {
  return { type, data };
};

const failure = (type, error = null) => {
  return { type, error };
};

/**
 * Signup User
 */
export const signupUser = (data) => {
  return (dispatch) => {
    dispatch(request(SIGNUP_REQUEST));

    let requestData = {
      ...data,
      user_id: uniqueKey(),
      created_at: Date.now(),
      added_by_user: 0,
      password: md5(data.password),
      phone_verify: false,
    };

    const apiName = "api";
    const path = "/users";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: requestData,
    };

    return new Promise((resolve, reject) => {
      API.put(apiName, path, myInit)
        .then((response) => {
          // Add your code here
          dispatch(success(SIGNUP_SUCCESS, requestData));
          resolve(requestData);
        })
        .catch((error) => {
          dispatch(failure(SIGNUP_FAIL, error.response.data.error));
          reject(error.response.data.error);
        });
    });
  };
};

/**
 * Login User
 */
export const loginUser = (data) => {
  return (dispatch) => {
    dispatch(request(LOGIN_REQUEST));

    let requestData = {
      email: data.email,
      user_role: data.user_role,
      password: md5(data.password),
    };

    const apiName = "api";
    const path = "/users/login";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: requestData,
    };

    return new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then((response) => {
          // Add your code here
          dispatch(success(LOGIN_SUCCESS, response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(failure(LOGIN_FAIL, error.response.data.error));
          reject(error.response.data.error);
          // dispatch(failure(LOGIN_FAIL, error.error))
          // reject(error.error)
        });
    });
  };
};
export const updateChange = (data) => {
  return (dispatch) => {
    dispatch(success(UPDATE_CHANGE, data));
  };
};
/**
 * socialLogin User
 */
export const socialLogin = (data) => {
  return (dispatch) => {
    dispatch(request(SOCIAL_LOGIN_REQUEST));

    let requestData = {
      ...data,
      created_at: Date.now(),
      added_by_user: 0,
      user_id: uniqueKey(),
      phone_verify: false,
      notifications: [],
    };

    const apiName = "api";
    const path = "/users/social_login";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: requestData,
    };

    return new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then((response) => {
          // Add your code here
          dispatch(success(SOCIAL_LOGIN_SUCCESS, response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(failure(SOCIAL_LOGIN_FAIL, error.response.data.error));
          reject(error.response.data.error);
          // dispatch(failure(LOGIN_FAIL, error.error))
          // reject(error.error)
        });
    });
  };
};

/**
 * Switch user account
 */
export const switchUserAccount = (data) => {
  return (dispatch) => {
    dispatch(request(SWITCH_USER_ACCOUNT_REQUEST));

    let requestData = data;

    const apiName = "api";
    const path = "/users/switch_user_account";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: requestData,
    };

    return new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then((response) => {
          // Add your code here
          dispatch(success(SWITCH_USER_ACCOUNT_SUCCESS, response.data));
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            dispatch(
              failure(SWITCH_USER_ACCOUNT_FAIL, error.response.data.error)
            );
            reject(error.response.data.error);
          } else {
            dispatch(failure(SWITCH_USER_ACCOUNT_FAIL, error.message));
            reject(error.message);
          }
        });
    });
  };
};
/**
 * Add Token
 */
export const addToken = (data) => {
  const apiName = "api";
  const path = "/users/addtoken";
  const myInit = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: data,
  };
  return API.post(apiName, path, myInit);
};

/**
 * Add User
 */
export const addUser = (data) => {
  return (dispatch) => {
    dispatch(request(ADD_USER_REQUEST));

    let requestData = {
      users: data,
    };

    const apiName = "api";
    const path = "/myusers";

    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: requestData,
    };

    return new Promise((resolve, reject) => {
      API.put(apiName, path, myInit)
        .then((response) => {
          // Add your code here

          dispatch(success(ADD_USER_SUCCESS, requestData));
          resolve(requestData);
        })
        .catch((error) => {
          NotificationManager.error("You have already added this user!");
          dispatch(failure(USERS_FAIL, error.message));
        });
    });
  };
};
export const updateMyUser = (data) => {
  return (dispatch) => {
    dispatch(request(ADD_USER_REQUEST));

    let requestData = {
      users: data,
    };

    const apiName = "api";
    const path = "/myusers/update";

    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: requestData,
    };

    return new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then((response) => {
          // Add your code here

          dispatch(success(ADD_USER_SUCCESS, requestData));
          resolve(requestData);
        })
        .catch((error) => {
          NotificationManager.error("You have already added this user!");
          dispatch(failure(USERS_FAIL, error.message));
        });
    });
  };
};
/**
 * List Users
 */
export const getUsers = (data) => {
  return (dispatch) => {
    dispatch(request(USERS_REQUEST));

    const apiName = "api";
    const path = "/myusers";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        queryParam: encodeURI(JSON.stringify(data)),
      },
    };

    return new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then((response) => {
          // Add your code here
          dispatch(success(USERS_SUCCESS, response.data));
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            dispatch(failure(USERS_FAIL, error.response.data.error));
            reject(error.response.data.error);
          } else {
            dispatch(failure(USERS_FAIL, error.message));
            reject(error.message);
          }
        });
    });
  };
};

export const loginAdmin = (data) => {
  const apiName = "api";
  const path = "/admin/login";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: { email: data.email, password: md5(data.password) },
  };

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        // Add your code here

        resolve(response.data);
      })
      .catch((error) => {
        alert("you are not admin");
      });
  });
};

export const getCMS = () => {
  const apiName = "api";
  const path = "/admin/getcms";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: {},
  };

  return API.get(apiName, path, myInit);
};
export const senNotificationRegister = (data) => {
  const apiName = "api";
  const path = "/users/pushnotificationregister";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: data,
  };

  return API.post(apiName, path, myInit);
};

export const getNotifications = (id) => {
  const apiName = "api";
  const path = "/users/getnotifications";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: { user_id: id },
  };

  return API.post(apiName, path, myInit);
};

export const updateCMS = (data) => {
  const apiName = "api";
  const path = "/admin/updatecms";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: data,
  };

  return API.post(apiName, path, myInit);
};

export const updateNotifications = (id) => {
  const apiName = "api";
  const path = "/users/updatenotifications";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: { user_id: id },
  };

  return API.post(apiName, path, myInit);
};
export const generateDeletionCode = (phone_num) => {
  const apiName = "api";
  const path = "/users/generatedeletioncode";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: { phoneNumber: phone_num },
  };

  return API.post(apiName, path, myInit);
};
export const generateVerifyCode = (phone_num) => {
  const apiName = "api";
  const path = "/users/generatconfirmcode";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: { phoneNumber: phone_num },
  };

  return API.post(apiName, path, myInit);
};
export const generateCode = (phone_num) => {
  const apiName = "api";
  const path = "/users/generatecode";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: { phoneNumber: phone_num },
  };

  return API.post(apiName, path, myInit);
};
export const reset = (data) => {
  const apiName = "api";
  const path = "/users/resetpassword";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: data,
  };

  return API.post(apiName, path, myInit);
};

export const getUsersForAdmin = (data) => {
  const apiName = "api";
  const path = "/admin/getusers";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return new Promise((resolve, reject) => {
    API.get(apiName, path, myInit)
      .then((response) => {
        // Add your code here

        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data.error);
        } else {
          reject(error.message);
        }
      });
  });
};

export const getTotalsForAdmin = (data) => {
  const apiName = "api";
  const path = "/admin/gettotals";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return new Promise((resolve, reject) => {
    API.get(apiName, path, myInit)
      .then((response) => {
        // Add your code here

        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data.error);
        } else {
          reject(error.message);
        }
      });
  });
};

export const getInvitesForAdmin = (data) => {
  const apiName = "api";
  const path = "/admin/getinvites";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return new Promise((resolve, reject) => {
    API.get(apiName, path, myInit)
      .then((response) => {
        // Add your code here

        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data.error);
        } else {
          reject(error.message);
        }
      });
  });
};

export const deleteInviteForAdmin = (data) => {
  const apiName = "api";
  const path = "/admin/deleteinvite";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: data,
  };

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        // Add your code here

        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data.error);
        } else {
          reject(error.message);
        }
      });
  });
};
export const deleteAccount = (data) => {
  return (dispatch) => {
    dispatch(request(DELETE_ACCOUNT_REQUEST));
    const apiName = "api";
    const path = "/users/deleteaccount";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: data,
    };

    return new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then((response) => {
          if (response) {
            dispatch(request(DELETE_ACCOUNT_SUCCESS));
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            reject(error.response.data.error);
          } else {
            reject(error);
          }
        });
    });
  };
};
export const verifyPhoneNumber = (data) => {
  return (dispatch) => {
    dispatch(request(VERIFY_PHONE_REQUEST));
    const apiName = "api";
    const path = "/users/verifyphone";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: data,
    };

    return new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then((response) => {
          if (response) {
            dispatch(request(VERIFY_PHONE_SUCCESS));
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            reject(error.response.data.error);
          } else {
            reject(error);
          }
        });
    });
  };
};
export const editUserForUsers = (data) => {
  return (dispatch) => {
    dispatch(request(UPDATE_REQUEST));
    let requestData = data;

    const apiName = "api";
    const path = "/users/updateuser";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      body: requestData,
    };

    return new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then((response) => {
          if (response.success) {
            dispatch(request(UPDATE_SUCCESS));
            resolve(response.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            reject(error.response.data.error);
          } else {
            reject(error.message);
          }
        });
    });
  };
};
export const editUserForAdmin = (data, changed) => {
  let requestData;
  if (changed) {
    requestData = {
      ...data,
      password: md5(data.password),
    };
  } else {
    requestData = data;
  }

  const apiName = "api";
  const path = "/admin/updateuser";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: requestData,
  };

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        // Add your code here

        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          alert(error.response.data.error);
          reject(error.response.data.error);
        } else {
          reject(error.message);
        }
      });
  });
};

export const createUserForAdmin = (data) => {
  let requestData = {
    ...data,
    user_id: uniqueKey(),
    created_at: Date.now(),
    added_by_user: 0,
    password: md5(data.password),
    phone_verify: false,
  };

  const apiName = "api";
  const path = "/admin/createuser";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: requestData,
  };

  return new Promise((resolve, reject) => {
    API.put(apiName, path, myInit)
      .then((response) => {
        // Add your code here

        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          reject(error.response.data.error);
        } else {
          reject(error.message);
        }
      });
  });
};
export const deleteUserForAdmin = (data) => {
  let requestData = data;
  const apiName = "api";
  const path = "/admin/deleteuser";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: requestData,
  };

  return new Promise((resolve, reject) => {
    API.post(apiName, path, myInit)
      .then((response) => {
        // Add your code here

        resolve(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          alert(error.response.data.error);
          reject(error.response.data.error);
        } else {
          reject(error.message);
        }
      });
  });
};
/**
 * Submit Questions
 */
export const submitQuestions = (data) => {
  return (dispatch) => {
    dispatch(request(QUESTION_SUBMIT_REQUEST));

    const apiName = "api";
    const path = "/questions";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: data,
    };

    return new Promise((resolve, reject) => {
      API.put(apiName, path, myInit)
        .then((response) => {
          // Add your code here
          dispatch(success(QUESTION_SUBMIT_SUCCESS, response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(failure(QUESTION_SUBMIT_FAIL, extractError(error)));
          reject(extractError(error));
        });
    });
  };
};

/**
 * Get Questions
 */
export const getQuestions = (data) => {
  return (dispatch) => {
    dispatch(request(QUESTION_GET_REQUEST));

    const apiName = "api";
    const path = "/questions";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        queryParam: encodeURI(JSON.stringify(data)),
      },
    };

    return new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then((response) => {
          // Add your code here
          dispatch(success(QUESTION_GET_SUCCESS, response.data));
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            dispatch(failure(QUESTION_GET_FAIL, error.response.data.error));
            reject(error.response.data.error);
          } else {
            dispatch(failure(QUESTION_GET_FAIL, error.message));
            reject(error.message);
          }
        });
    });
  };
};

export const deleteInvitesAction = (data) => {
  const apiName = "api";
  const path = "/users/deletemultipleinvites";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: { invites: data },
  };

  return API.post(apiName, path, myInit);
};

export const deleteFormAndInvite = (data) => {
  const apiName = "api";
  const path = "/users/deleteforms";
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    body: { forms: data },
  };

  return API.post(apiName, path, myInit);
};
/**
 * Invite User
 */
export const inviteUser = (data) => {
  return (dispatch) => {
    dispatch(request(INVITE_USER_REQUEST));

    const apiName = "api";
    const path = "/invite";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: { invites: data },
    };

    return new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then((response) => {
          dispatch(success(INVITE_USER_SUCCESS, response.data));
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            dispatch(failure(INVITE_USER_FAIL, error.response.data.error));
            reject(error.response.data.error);
          } else {
            dispatch(failure(INVITE_USER_FAIL, error.message));
            reject(error.message);
          }
        });
    });
  };
};

/**
 * Get Questions
 */
export const getInvites = (data) => {
  return (dispatch) => {
    dispatch(request(GET_INVITES_REQUEST));

    const apiName = "api";
    const path = "/invite";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        queryParam: encodeURI(JSON.stringify(data)),
      },
    };

    return new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then((response) => {
          // Add your code here
          dispatch(success(GET_INVITES_SUCCESS, response.data));
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            dispatch(failure(GET_INVITES_FAIL, error.response.data.error));
            reject(error.response.data.error);
          } else {
            dispatch(failure(GET_INVITES_FAIL, error.message));
            reject(error.message);
          }
        });
    });
  };
};

/**
 * Submit Answer
 */
export const submitAnswer = (data) => {
  return (dispatch) => {
    dispatch(request(SUBMIT_ANSWER_REQUEST));

    const apiName = "api";
    const path = "/answer/submit";

    return new Promise((resolve, reject) => {
      let requestData = {
        ...data,
        answer_id: uniqueKey(),
        answered_at: Date.now(),
      };

      const myInit = {
        // OPTIONAL
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        body: requestData,
      };

      API.post(apiName, path, myInit)
        .then((response) => {
          dispatch(success(SUBMIT_ANSWER_SUCCESS, response.data));
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            dispatch(failure(SUBMIT_ANSWER_FAIL, error.response.data.error));
            reject(error.response.data.error);
          } else {
            dispatch(failure(SUBMIT_ANSWER_FAIL, error.message));
            reject(error.message);
          }
        });
    });
  };
};

/**
 * Get Questions
 */
export const getAnswers = (data) => {
  return (dispatch) => {
    dispatch(request(GET_ANSWERS_REQUEST));

    const apiName = "api";
    const path = "/answer";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        queryParam: encodeURI(JSON.stringify(data)),
      },
    };

    return new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then((response) => {
          // Add your code here
          dispatch(success(GET_ANSWERS_SUCCESS, response.data));
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            dispatch(failure(GET_ANSWERS_FAIL, error.response.data.error));
            reject(error.response.data.error);
          } else {
            dispatch(failure(GET_ANSWERS_FAIL, error.message));
            reject(error.message);
          }
        });
    });
  };
};
