import React, { useState, useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import ActionsImage from "../images/features.png";

import { getCMS } from "../../../actions/index";
import UwUpdate from "./UwVideo/Uwupdate";
export default function UWInActionPage() {
  const [data, set_data] = useState([]);
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    Update();
  }, []);

  const Seperate = (name) => {
    let seperated = null;
    data.map((item) => {
      console.log(item.content_id === name);
      if (item.content_id === name) seperated = item;
    });

    return seperated;
  };

  const Update = () => {
    set_loading(true);
    getCMS()
      .then((r) => {
        set_loading(false);
        set_data(r.data);
      })
      .catch((e) => console.log(e));
  };
  console.log(Seperate("home_entry"));
  return (
    <LoadingOverlay active={loading} spinner text="Please wait...">
      <UwUpdate
        inputs={[
          {
            title: "title_1",
            placeholder: "Title 1",
            content: "content_1",
            video: "video_1",
          },
          {
            title: "title_2",
            placeholder: "Title 2",
            content: "content_2",
            video: "video_2",
          },
          {
            title: "title_3",
            placeholder: "Title 3",
            content: "content_3",
            video: "video_3",
          },
          {
            title: "title_4",
            placeholder: "Title 4",
            content: "content_4",
            video: "video_4",
          },
        ]}
        update={Update}
        url={ActionsImage}
        data={Seperate("uwactions_carts")}
      />
    </LoadingOverlay>
  );
}
