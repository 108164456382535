import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { CircularProgress, Input, TextField } from "@material-ui/core";
import { getInvitesForAdmin, deleteInviteForAdmin } from "../../actions/index";
import Modal from "./Modal";
import CreateUserForm from "../../admin/createUser";
import { Button } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "80vh",
  },
});

export default function StickyHeadTable({ history }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [id, setid] = React.useState(0);
  const [filtered, setFiltered] = React.useState([]);
  const [open, setopen] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [rows, setRows] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  const handleOpen = (id) => {
    setid(id);
    setopen(true);
  };

  const handleClose = () => {
    setopen(false);
  };

  const del = (data) => {
    deleteInviteForAdmin(data).then((response) => {
      Update();
    });
  };

  React.useEffect(() => {
    const user = localStorage.getItem("ADMIN");

    if (!user) {
      if (history) history.push("/adminlogin");
    } else {
      //history.push("adminlogin");
    }
  }, []);
  /*
     const handleSubmit=(obj)=> {
      
    
    
        //Then cipher any text:
      
    
        editUser(obj).then(
          (response) => {
          console.log(response)
    
            if (response.success) {
              Update()

              




            } else {
                setloading(false)
             
            }
          }
        );
      }


*/

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function createData(
    invite_id,
    invite_by_user,
    invite_by_email,
    invite_to_email,
    del
  ) {
    return { invite_id, invite_by_user, invite_by_email, invite_to_email, del };
  }

  const columns = [
    { id: "invite_id", label: "invite_id", minWidth: 170 },
    { id: "invite_by_user", label: "invite_by_user", minWidth: 100 },
    { id: "invite_by_email", label: "invite_by_email", minWidth: 100 },
    {
      id: "invite_to_email",
      label: "invite_to_email",
      minWidth: 100,
    },

    {
      id: "del",
      label: "delete",
      minWidth: 50,
      align: "right",
    },
  ];
  const users = [
    createData(
      0,
      "16 Mar, 2019",
      "Elvis Presley",
      "Tupelo, MS",
      "VISA ⠀•••• 3719",
      312.44
    ),
    createData(
      1,
      "16 Mar, 2019",
      "Paul McCartney",
      "London, UK",
      "VISA ⠀•••• 2574",
      866.99
    ),
    createData(
      2,
      "16 Mar, 2019",
      "Tom Scholz",
      "Boston, MA",
      "MC ⠀•••• 1253",
      100.81
    ),
    createData(
      3,
      "16 Mar, 2019",
      "Michael Jackson",
      "Gary, IN",
      "AMEX ⠀•••• 2000",
      654.39
    ),
    createData(
      4,
      "15 Mar, 2019",
      "Bruce Springsteen",
      "Long Branch, NJ",
      "VISA ⠀•••• 5919",
      212.79
    ),
  ];

  React.useEffect(() => {
    Update();
  }, []);

  const _filter = (e) => {
    let searchKey = e.target.value;

    let filtArr = rows.filter((row) => {
      return row.invite_by_user.includes(searchKey);
    });
    setFiltered(filtArr);
  };

  const Update = () => {
    setloading(true);
    getInvitesForAdmin()
      .then((r) => {
        const Users = r ? r : [];

        const rows = [];
        Users.map((user) =>
          rows.push(
            createData(
              user.invite_id,
              user.invite_by_username,
              user.invite_by_email,
              user.invite_to_email,
              <BackspaceIcon
                style={{ color: "#eb1c24" }}
                className="needHover"
                onClick={() =>
                  del({
                    invite_id: user.invite_id,
                    invite_by_user: user.invite_by_user,
                  })
                }
              ></BackspaceIcon>
            )
          )
        );

        setRows(rows);
        setFiltered(rows);
        setopen(false);
        setTimeout(() => {
          setloading(false);
        }, 500);
      })
      .catch(() => {
        setloading(false);

        alert("error");
      });
  };

  if (!loading)
    return (
      <Paper className={classes.root}>
        <TextField
          onChange={_filter}
          id="outlined-basic"
          label="Filter by User A name"
          variant="outlined"
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filtered
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    );
  else
    return (
      <CircularProgress
        style={{ position: "absolute", left: "50%" }}
      ></CircularProgress>
    );
}
