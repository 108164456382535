import React, { useState, useEffect } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { updateCMS } from "../../../../actions/index";
import Upload from "../../uploadVideo";
export default function VideoBase({
  value,
  name,
  inputs,
  url,
  data,
  update,
  setUrl,
}) {
  const [loading, set_loading] = useState(false);
  const [obj, set_obj] = useState({});
  const [allow, set_allow] = useState(true);
  const [video_url, set_video_url] = useState(url);
  const handleSubmit = (e) => {
    e.preventDefault();

    let submit_obj = {
      data: obj,
      content_id: data.content_id,
    };
    console.log(submit_obj);
    updateCMS(submit_obj)
      .then((r) => {
        console.log(r);
        update();
      })
      .catch((e) => alert("an error occured"));
  };

  useEffect(() => {
    set_obj(data ? data.data : {});
  }, [data]);

  useEffect(() => {
    console.log("32", value);
    if (value) {
      set_allow(false);
    }
  }, [value]);

  const handleChange = (name, value) => {
    let new_obj = { ...obj };
    new_obj[name] = value;
    set_obj(new_obj);
  };

  const setImgUrl = (url) => {
    setUrl(name, url);
  };

  console.log("obj", obj);
  console.log(allow);
  return (
    <div>
      {allow ? (
        <Upload setImageUrl={setImgUrl} url={value}></Upload>
      ) : (
        <div>
          <a href={value} style={{ color: "#3f47cc" }}>
            see video
          </a>
          <p
            onClick={() => set_allow(true)}
            style={{ cursor: "pointer", color: "#0ed145" }}
          >
            or upload new one
          </p>
        </div>
      )}
    </div>
  );
}
