import React, { useEffect, useState } from "react";
import "./YoutubeSection.css";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
function YoutubeSection({ homeVideo }) {
  const [obj, set_obj] = useState({});
  useEffect(() => {
    set_obj(homeVideo ? homeVideo.data : {});
  }, [homeVideo]);

  return (
    <div className="homePage">
      <div className="homePage_videoContainer">
        <Grid container>
          <Grid item xs={12} lg={12}>
            <div className="homePage__info">
              <h2 className="homePage__info__h2 tema-blue">
                {obj && obj.title}
              </h2>
            </div>
          </Grid>
          <Grid item xs={12} lg={12} className="homePage__videoPhone_">
            <div className="homePage__videoPhone_bg_"></div>
            <div className="homePage__videoPhone_iframe_">
              <ReactPlayer
                url={obj.video_url}
                controls
                width={265}
                style={{ position: "relative", right: "8px", bottom: "40px" }}
                height={610}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default YoutubeSection;
