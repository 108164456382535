import React, { useEffect, useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import { getTotalsForAdmin } from "../../actions/index";
import { CircularProgress } from "@material-ui/core";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "./variables/charts.js";

import Header from "./header.js";

const Statpage = () => {
  const [loading, set_loading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [data, set_data] = useState({
    users: 10,
    videos: 10,
    questions: 20,
    average_video_length: 30,
  });
  const [data_array, set_data_array] = useState({
    users: 10,
    videos: 10,
    questions: 20,
    average_video_length: 30,
  });
  const [date_by_data, set_date_by_data] = useState({
    users: 10,
    videos: 10,
    questions: 20,
    average_video_length: 30,
  });

  const findAverage = (videos) => {
    let totalSecond = 0;
    videos.map((video) => {
      totalSecond += video.duration;
    });
    let totalMinute = totalSecond;
    return videos.length === 0
      ? 0
      : Math.floor((totalMinute / videos.length) * 100) / 100;
  };

  Date.prototype.withoutTime = function () {
    var d = new Date(this);
    d.setHours(0, 0, 0, 0);
    return d;
  };

  const updateDatas = () => {
    console.log(data_array);
    let updatedObj = {};
    let started = new Date(startDate).withoutTime();
    let ended = new Date(endDate).withoutTime();
    console.log(started);
    let new_users = data_array.users.filter((item) => {
      return (
        new Date(item.created_at).withoutTime() >= started &&
        new Date(item.created_at).withoutTime() <= ended
      );
    });

    let new_videos = data_array.videos.filter((item) => {
      return (
        new Date(item.answered_at).withoutTime() >= started &&
        new Date(item.answered_at).withoutTime() <= ended
      );
    });

    let new_questions = data_array.questions.filter((item) => {
      return (
        new Date(item.created_at).withoutTime() >= started &&
        new Date(item.created_at).withoutTime() <= ended
      );
    });

    updatedObj = {
      users: new_users.length,
      questions: new_questions.length,
      average_video_length: findAverage(new_videos),
      videos: new_videos.length,
    };
    set_date_by_data(updatedObj);
  };

  const fetch = async () => {
    set_loading(true);
    try {
      const r = await getTotalsForAdmin();
      console.log("response", r);
      let initialObj = {
        users: r.users?.length,
        videos: r.videos?.length,
        questions: r.questions?.length,
        average_video_length: findAverage(r.videos),
      };
      let allArray = {
        users: r.users,
        videos: r.videos,
        questions: r.questions,
      };

      set_data(initialObj);
      set_date_by_data(initialObj);
      set_data_array(allArray);
      set_loading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const toggleNavs = (e, index) => {
    e.preventDefault();
  };

  if (!loading)
    return (
      <>
        <Header data={data} date={false} />
        <hr></hr>
        <div className="fullCenter">
          <Row className="fullCenter">
            <Col xs="12" xl="4" className="fullCenter">
              {" "}
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Col>

            <Col xs="12" xl="4" className="fullCenter">
              {" "}
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Col>

            <Col xs="12" xl="4" className="fullCenter">
              <Button
                variant="contained"
                className="ubuntu-red"
                style={{ marginLeft: 30 }}
                onClick={updateDatas}
              >
                Filter
              </Button>
            </Col>
          </Row>
        </div>

        <Header data={date_by_data} date={true} />
        {/* Page content */}
      </>
    );
  else
    return (
      <CircularProgress
        style={{ position: "absolute", left: "50%" }}
      ></CircularProgress>
    );
};

export default Statpage;
/**
 *  getUsersForAdmin().then((r)=>{
set_total_users({today:CalculateToday(r),month:CalculateMonth(r),year:CalculateYear(r)})

})
 * 
 * const [date,set_date]=useState("month")
const [total_users,set_total_users]=useState({today:10,month:50,year:20})
const [total_questions,set_total_questions]=useState({today:10,month:50,year:20})
const [total_videos,set_total_videos]=useState({today:10,month:50,year:20})
const [users_added,set_users_added]=useState({today:10,month:50,year:20})
const [average_length,set_average_length]=useState({today:10,month:50,year:20})
const [questions_added,set_questions_added]=useState({today:10,month:50,year:20})
const [videos_added,set_videos_added]=useState({today:10,month:50,year:20})
 */
