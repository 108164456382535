import { keys } from "@material-ui/core/styles/createBreakpoints";
import {
  ADD_USER_FAIL,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SIGNUP_FAIL,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SOCIAL_LOGIN_FAIL,
  SOCIAL_LOGIN_REQUEST,
  SOCIAL_LOGIN_SUCCESS,
  SWITCH_USER_ACCOUNT_FAIL,
  SWITCH_USER_ACCOUNT_REQUEST,
  SWITCH_USER_ACCOUNT_SUCCESS,
  USERS_FAIL,
  USERS_REQUEST,
  USERS_SUCCESS,
  DROPDOW_CHANGE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_CHANGE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  VERIFY_PHONE_SUCCESS,
  VERIFY_PHONE_REQUEST,
} from "../actions/types";

const userItem = localStorage.getItem("LOGGED_USER");

const defaultProps = {
  accountSwitching: false,
  loading: false,
  user: userItem ? JSON.parse(userItem) : null,
  users: [],
  isDropDown: false,
};
const userReducer = (state = defaultProps, action) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DROPDOW_CHANGE:
      return {
        ...state,
        isDropDown: !state.isDropDown,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        user: action.data,
      };
    case "UPDATE_USER":
      return { ...state };
    case DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VERIFY_PHONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SIGNUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CHANGE:
      return {
        ...state,
        loading: false,
        user: action.data,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        user: action.data,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SOCIAL_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        user: action.data,
      };
    case SOCIAL_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        users: action.data,
      };
    case USERS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case ADD_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ADD_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SWITCH_USER_ACCOUNT_REQUEST:
      return {
        ...state,
        accountSwitching: true,
        error: null,
      };
    case SWITCH_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountSwitching: false,
        error: null,
        user: action.data,
      };
    case SWITCH_USER_ACCOUNT_FAIL:
      return {
        ...state,
        accountSwitching: false,
        error: action.error,
      };

    default:
      return state;
  }
};
export default userReducer;
