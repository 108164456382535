import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import {
  loginUser,
  senNotificationRegister,
  socialLogin,
  switchUserAccount,
  reset,
  generateCode,
} from "../../../actions";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { userBIdentityForInviteSignin } from "../../../helpers/util";
import { tagPropType } from "reactstrap/lib/utils";
import { NotificationManager } from "react-notifications";
import md5 from "md5";
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      phoneNumber: "",
      password: "",
      confirm: "",
      code: "",
      nextStep: false,
    };
  }

  componentDidMount() {
    const user = localStorage.getItem("LOGGED_USER");
    if (user) {
      this.props.history.push("/dashboard");
    }
  }

  checkPhone = () => {
    var isphone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      this.state.phoneNumber
    );

    if (!isphone) {
      this.setState({
        error: "Please enter a valid phone number",
        message: "",
      });
      return true;
    }
    return false;
  };

  checkPhonenumber = (number) => {
    let correct_phoneNumber = number;

    if (correct_phoneNumber) {
      if (!correct_phoneNumber.includes("+")) {
        correct_phoneNumber = "+" + correct_phoneNumber;
      }
    }

    return correct_phoneNumber;
  };

  confirm = () => {
    if (this.state.password === this.state.confirm) {
      if (this.state.code) {
        let phone = this.checkPhonenumber(this.state.phoneNumber);
        let data = {
          phoneNumber: phone,
          password: md5(this.state.password),
          resetCode: parseInt(this.state.code),
        };
        reset(data)
          .then((res) => {
            if (res.data && res.data.succes) {
              NotificationManager.success("your password changed");
              this.props.history.push("/login");
            } else {
              this.setState({
                error: "Please enter a valid phone number",
              });
            }
          })
          .catch((e) => {
            console.log(e);
            this.setState({
              error: "Code is not valid",
            });
          });
      } else {
        this.setState({
          error: "code is not valid",
          message: "",
        });
      }
    } else {
      this.setState({
        error: "passwords should match",
        message: "",
      });
    }
  };

  sendCode = () => {
    if (!this.checkPhone()) {
      let phone = this.checkPhonenumber(this.state.phoneNumber);

      //send api phone
      generateCode(phone)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.succes) {
            this.setState({ nextStep: true });
          } else {
            this.setState({
              error: "Please enter a valid phone number",
            });
          }
        })
        .catch((e) => {
          this.setState({
            error: "Please enter a valid phone number",
          });
        });
    }
  };

  render() {
    return (
      <LoadingOverlay
        active={this.props.user.loading}
        spinner
        text="Validating..."
      >
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                      <h1>Reset password</h1>

                      {this.state.error && (
                        <Alert color="danger">{this.state.error}</Alert>
                      )}
                      {this.state.message && (
                        <Alert color="success">{this.state.message}</Alert>
                      )}

                      {!this.state.nextStep ? (
                        <div className="mt-6">
                          <PhoneInput
                            country={"us"}
                            className="mt-3"
                            style={{ width: 300 }}
                            value={this.state.phoneNumber}
                            onChange={(phone) => {
                              this.setState({ phoneNumber: phone });
                            }}
                          />
                          <Button
                            type={"submit"}
                            className="px-4 mt-6 ubuntu-blue"
                            onClick={this.sendCode}
                          >
                            Send code
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <h3>
                            Your phone number on record will receive a code
                            shortly.
                          </h3>
                          <InputGroup className="mb-3 mt-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="password"
                              placeholder="password"
                              autoComplete="password"
                              value={this.state.password}
                              onChange={(event) => {
                                this.setState({ password: event.target.value });
                              }}
                            />
                          </InputGroup>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="password"
                              placeholder="Confirm  Password"
                              autoComplete="current-password"
                              value={this.state.confirm}
                              onChange={(event) => {
                                this.setState({ confirm: event.target.value });
                              }}
                            />
                          </InputGroup>

                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="code"
                              placeholder="code (will expire in 5 min)"
                              autoComplete="current-code"
                              value={this.state.code}
                              onChange={(event) => {
                                this.setState({ code: event.target.value });
                              }}
                            />
                          </InputGroup>
                          <Row>
                            <Col xs="6">
                              <Button
                                type={"submit"}
                                className="px-4 ubuntu-blue"
                                onClick={this.confirm}
                              >
                                Change password
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapDispatchToProps = {
  loginUser,
  socialLogin,
  switchUserAccount,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
