/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { PeopleAlt, VideoLibrary, Help, Add } from "@material-ui/icons";
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class Header extends React.Component {
  render() {
    let renderArray = [];

    if (this.props.date) {
      renderArray = [
        { name: "users", icon: <PeopleAlt />, label: "Added User" },
        { name: "questions", icon: <Help />, label: "Added Questions" },
        {
          name: "average_video_length",
          icon: <VideoLibrary />,
          label: "Average video length",
        },
        { name: "videos", icon: <VideoLibrary />, label: "Added videos" },
      ];
    } else {
      renderArray = [
        { name: "users", icon: <PeopleAlt />, label: "Total User" },
        { name: "questions", icon: <Help />, label: "Total Questions" },
        {
          name: "average_video_length",
          icon: <VideoLibrary />,
          label: "Average video length",
        },
        { name: "videos", icon: <VideoLibrary />, label: "Total videos" },
      ];
    }

    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                {renderArray.map((item) => {
                  return (
                    <Col lg="6" xl="4" className="mb-4">
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                              >
                                {item.label}
                              </CardTitle>
                              <span className="h3 font-weight-bold mb-0">
                                {this.props.data[item.name]}
                                {item.name === "average_video_length" &&
                                  "  second"}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape text-white rounded-circle shadow ubuntu-red">
                                {item.icon}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
