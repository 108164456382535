import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
// core components
import Logo from "./UWlogo.png";
import Header from "./header.ui.js";

import styles from "./navbarsStyle.js";

const useStyles = makeStyles(styles);

const HHeader = (props) => {
  const classes = useStyles();
  return (
    <Header
      color="white"
      fixed
      changeColorOnScroll={{
        height: 400,
        color: "white",
      }}
      leftLinks={
        <Link className="leftLogoContainer" color="transparent" to="/home">
          <img src={Logo} className="headerLogo"></img>
          <h3 className="headerLink leftLogoLink tema-green-text">
            Ubuntu World
          </h3>
        </Link>
      }
      rightLinks={
        <List className="rightLinksContainer">
          <ListItem className="rightLinkItem">
            <Link color="transparent" to="/login">
              <h4 className="rightLink">Start</h4>
            </Link>
          </ListItem>
          <ListItem className="rightLinkItem">
            <Link color="transparent" to="/why-uw">
              <h4 className="rightLink">Why UW</h4>
            </Link>
          </ListItem>
          <ListItem className="rightLinkItem">
            <Link color="transparent" to="/uw-in-action">
              <h4 className="rightLink">UW in Action</h4>
            </Link>
          </ListItem>
          <ListItem className="rightLinkItem">
            <Link color="transparent" to="/giving-back">
              <h4 className="rightLink">Giving Back</h4>
            </Link>
          </ListItem>
          <ListItem className="rightLinkItem">
            <Link color="transparent" to="/faqs">
              <h4 className="rightLink">Support</h4>
            </Link>
          </ListItem>
          <ListItem className="rightLinkItem">
            <Link color="transparent" to="/contact">
              <h4 className="rightLink">Contact Us</h4>
            </Link>
          </ListItem>
        </List>
      }
    />
  );
};

export default HHeader;
