import React from "react";
import Entry from "./components/entry/entry";
import Footer from "./components/Footer/Footer";
import HomeInfo from "./components/HomeInfo/HomeInfo";
import LearnMore from "./components/LearnMore/LearnMore";
import SoMuchMore from "./components/SoMuchMore/SoMuchMore";
import YoutubeSection from "./components/YoutubeSection/YoutubeSection";

export default function index({ homeEntry, homeCart, homeVideo }) {
  return (
    <div className="homeContainer">
      <Entry homeEntry={homeEntry}></Entry>
      <YoutubeSection homeVideo={homeVideo} />
      <HomeInfo homeCart={homeCart} />
    </div>
  );
}
