import React, { useState, useEffect } from "react";
import UpdateContent from "../UpdateContent";
import LoadingOverlay from "react-loading-overlay";
import UWEntryImage from "../images/uw_entry.png";
import UWCartsImage from "../images/uw_carts.png";

import { getCMS } from "../../../actions/index";
export default function GivingBackPage() {
  const [data, set_data] = useState([]);
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    Update();
  }, []);

  const Seperate = (name) => {
    let seperated = null;
    data.map((item) => {
      console.log(item.content_id === name);
      if (item.content_id === name) seperated = item;
    });

    return seperated;
  };

  const Update = () => {
    set_loading(true);
    getCMS()
      .then((r) => {
        set_loading(false);
        set_data(r.data);
      })
      .catch((e) => console.log(e));
  };
  console.log(Seperate("home_entry"));
  return (
    <LoadingOverlay active={loading} spinner text="Please wait...">
      <UpdateContent
        inputs={[
          { name: "title_1", placeholder: "Title 1" },
          { name: "content_1", placeholder: "Content 1" },
          { name: "title_2", placeholder: "Title 2" },
          { name: "content_2", placeholder: "Content 2" },
          { name: "title_3", placeholder: "Title 3" },
          { name: "content_3", placeholder: "Content 3" },
          { name: "title_4", placeholder: "Title 4" },
          { name: "content_4", placeholder: "Content 4" },
        ]}
        update={Update}
        url={UWCartsImage}
        data={Seperate("givingback_carts")}
      />
      <UpdateContent
        inputs={[
          { name: "title_1", placeholder: "Title 1" },
          { name: "content_1", placeholder: "Content 1" },
          { name: "title_2", placeholder: "Title 2" },
          { name: "content_2", placeholder: "Content 2" },
          { name: "title_3", placeholder: "Title 3" },
          { name: "content_3", placeholder: "Content 3" },
          { name: "title_4", placeholder: "Title 4" },
          { name: "content_4", placeholder: "Content 4" },
        ]}
        update={Update}
        url={UWEntryImage}
        data={Seperate("givingback_entry")}
      />
    </LoadingOverlay>
  );
}
