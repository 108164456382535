const defaultProps = {
  notifications: [],
};

const NotificationReducer = (state = defaultProps, action) => {
  switch (action.type) {
    case "SET_NOTIFICATIONS":
      return {
        ...state,
        notifications: action.payload,
      };

    default:
      return state;
  }
};
export default NotificationReducer;
