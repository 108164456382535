import React, { useState, useEffect } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import { updateCMS } from "../../../src/actions/index";
import Upload from "./uploadVideo";
export default function Contentcomponent({ inputs, url, data, update }) {
  const [loading, set_loading] = useState(false);
  const [obj, set_obj] = useState({});
  const [allow, set_allow] = useState(true);
  const handleSubmit = (e) => {
    e.preventDefault();

    let submit_obj = {
      data: obj,
      content_id: data.content_id,
    };

    updateCMS(submit_obj)
      .then((r) => {
        console.log(r);
        update();
      })
      .catch((e) => alert("an error occured"));
  };

  useEffect(() => {
    set_obj(data ? data.data : {});
  }, [data]);

  useEffect(() => {
    let url = obj ? obj["video_url"] : null;
    if (url) {
      set_allow(false);
    }
  }, [obj]);

  const handleChange = (name, value) => {
    let new_obj = { ...obj };
    new_obj[name] = value;
    set_obj(new_obj);
  };

  const setImgUrl = (url) => {
    let new_obj = { ...obj };
    new_obj["video_url"] = url;
    set_obj(new_obj);
  };

  return (
    <Paper elevation={3} className="mt-4 mb-8">
      <Grid
        container
        direction="row"
        justify="center"
        className="container"
        spacing={3}
      >
        <Grid container lg={6} alignItems="center">
          <img src={url} className="fullw"></img>
        </Grid>
        <Grid item lg={6} className="pl-6 pt-3 pb-1 fullw">
          <div>
            {allow ? (
              <Upload
                setImageUrl={setImgUrl}
                url={obj && obj["video_url"]}
              ></Upload>
            ) : (
              <div>
                <a href={obj["video_url"]} style={{ color: "#3f47cc" }}>
                  see video
                </a>
                <p
                  onClick={() => set_allow(true)}
                  style={{ cursor: "pointer", color: "#0ed145" }}
                >
                  or upload new one
                </p>
              </div>
            )}
            {inputs.map((item) => (
              <div className="form-group">
                <h6 style={{ color: "red" }}>{item.placeholder} </h6>
                <input
                  type="text"
                  value={obj && obj[item.name]}
                  onChange={(e) => handleChange(item.name, e.target.value)}
                  name={item.name}
                  className="form-control"
                  required
                  autoFocus
                />
              </div>
            ))}
          </div>
          <Button
            onClick={handleSubmit}
            className="fullw mb-2 ubuntu-blue"
            variant="contained"
            disabled={loading}
          >
            {loading ? "Changing..." : "Change content"}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
