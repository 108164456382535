import React, { useEffect, useState } from "react";
import "./HomeInfo.css";
import Grid from "@material-ui/core/Grid";

function HomeInfo({ homeCart }) {
  const [obj, set_obj] = useState({});
  useEffect(() => {
    set_obj(homeCart ? homeCart.data : {});
  }, [homeCart]);
  console.log(obj.title);
  return (
    <div className="homeInfo">
      <div className="homeInfo_videoContainer">
        <Grid container>
          <Grid item xs={12} lg={12}>
            <div className="homeInfo__info">
              <h2 className="homeInfo__info__h2 tema-red">{obj.title}</h2>
            </div>
          </Grid>
          <Grid container item xs={12} lg={12}>
            <Grid item lg={6} xs={12} sm={6}>
              <div className="homeInfo__info__card">
                <img
                  src="https://www.flaticon.com/svg/static/icons/svg/1256/1256659.svg"
                  className="homeInfo__info__card__img"
                />
                <h4 className="homeInfo__info__card__h4 tema-blue">
                  {obj.title_1}
                </h4>
                <p className="homeInfo__info__card__p">{obj.content_1}</p>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <div className="homeInfo__info__card">
                <img
                  src="https://www.flaticon.com/svg/static/icons/svg/1256/1256675.svg"
                  className="homeInfo__info__card__img"
                />
                <h4 className="homeInfo__info__card__h4 tema-blue">
                  {obj.title_2}
                </h4>
                <p className="homeInfo__info__card__p">{obj.content_2}</p>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <div className="homeInfo__info__card">
                <img
                  src="https://www.flaticon.com/svg/static/icons/svg/1256/1256661.svg"
                  className="homeInfo__info__card__img"
                />
                <h4 className="homeInfo__info__card__h4 tema-blue">
                  {obj.title_3}
                </h4>
                <p className="homeInfo__info__card__p">{obj.content_3}</p>
              </div>
            </Grid>
            <Grid item lg={6} xs={12} sm={6}>
              <div className="homeInfo__info__card">
                <img
                  src="https://www.flaticon.com/svg/static/icons/svg/1256/1256658.svg"
                  className="homeInfo__info__card__img"
                />
                <h4 className="homeInfo__info__card__h4 tema-blue">
                  {obj.title_4}
                </h4>
                <p className="homeInfo__info__card__p">{obj.content_4}</p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default HomeInfo;
