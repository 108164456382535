import React, { Component } from "react";
import "./VideoUpload.scss";

const styles = {
  inputWrapper: "input-wrapper",
  inputCover: "input-cover",
  helpText: "help-text",
  fileName: "file-name",
  fileNameStretch: "file-name spacer",
  fileExt: "file-ext",
  fileDrag: "file-drag",
  input: "input",
  loader: "loader",
  disabled: "disabled",
  loading: "loading",
  loaderItem: "loader-item",
  spacer: "spacer",
  button: "button",
  hover: "hover",
  imagePreview: "image-preview",
  preview: "preview",
  previewItem: "preview-item",
  previews: "previews",
};

const Loader = () => {
  return (
    <div className={styles.loader}>
      <span className={styles.loaderItem} />
      <span className={styles.loaderItem} />
      <span className={styles.loaderItem} />
    </div>
  );
};

class FilePreview extends Component {
  state = {
    loading: true,
  };

  getDefaultProps() {
    return {
      onRemove: () => {},
    };
  }

  componentWillMount() {
    this.loadData();
  }
  getExtFromName = (name) => {
    const parts = name.split(".");
    return parts[0];
  };
  componentWillReceiveProps(newProps) {
    this.loadData(newProps.data);
  }

  loadData = (data = this.props.data) => {
    if (!data) {
      return;
    }
    const reader = new FileReader();
    let type = "";
    if (data.type.match("text")) {
      type = "text";
    } else if (data.type.match("image")) {
      type = "image";
    } else {
      type = data.type;
    }

    reader.onload = (e) => {
      const src = e.target.result;
      this.setState({
        src,
        type,
        loading: false,
      });
    };
    if (type === "text") {
      reader.readAsText(data);
    } else if (type === "image") {
      reader.readAsDataURL(data);
    } else {
      this.setState({
        src: false,
        type,
        loading: false,
      });
    }
  };

  render() {
    let loading = null;
    if (this.state.loading) {
      loading = "loading data...";
    } else {
      loading = null;
    }

    let uploading = null;
    if (this.props.data.loading) {
      uploading = <Loader />;
    } else {
      uploading = null;
    }

    let preview = null;
    if (!this.state.loading && !this.props.data.loading) {
      if (this.state.type === "text") {
        preview = <pre className={styles.preview}>{this.state.src}</pre>;
      } else if (this.state.type === "image") {
        preview = (
          <img
            alt="preview"
            src={this.state.src}
            className={styles.imagePreview}
          />
        );
      } else {
        preview = <pre className={styles.preview}></pre>;
      }
    } else {
      preview = null;
    }
    const classes = [
      styles.previewItem,
      this.props.data.loading ? styles.disabled : "",
    ]
      .join(" ")
      .trim();
    return (
      <div className={classes}>
        {uploading}
        {loading}
        {preview}
        <div className={styles.fileNameStretch}>
          {this.getExtFromName(this.props.data.name)}
        </div>
        <button className="ubuntu-red" onClick={this.props.onRemove}>
          remove
        </button>
        <button className="ubuntu-green" onClick={this.props.onUpload}>
          upload
        </button>
      </div>
    );
  }
}

export default FilePreview;
