import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Logo from "../../header/UWlogo.png";
import "./Faqs.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%  ",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
    color: "#e91e63",
  },
}));

export default function SimpleAccordion({ questions }) {
  const classes = useStyles();
  const [obj, set_obj] = useState([]);
  useEffect(() => {
    set_obj(questions ? questions.data : []);
  }, [questions]);
  

  const Filter = (index) => {
    let array = obj.filter((item) => item.ix !== index);
    return array;
  };
  return (
    <div className="mt-6 mb-6 centerContainer">
      <img src={Logo} className="faqLogo"></img>
      <h2 className="faqTitle mb-3 tema-blue">FAQs for Ubuntu World</h2>

      {obj.map((question, index) => {
        if (index < obj.length / 2) {
          let arr = Filter(index);
          return (
            <Accordion className="" style={{ width: "50%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#e91e63" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h2 className={classes.heading}>{arr[0]?.val}</h2>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{arr[1]?.val}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        }
      })}
    </div>
  );
}
