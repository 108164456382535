import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
//pages
import HomeContent from "./pages/homePage";
import WhyUWPage from "./pages/whyuwPage";
import GivingBackPage from "./pages/givingbackPage";
import UWInActionPage from "./pages/uwinActionPage";
import SupportPage from "./pages/supportPage";
import PrivacyPage from "./pages/privacyPage";
import Footer from "./pages/footer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Home page" {...a11yProps(0)} />
          <Tab label="Why Uw" {...a11yProps(1)} />
          <Tab label="UW in Action" {...a11yProps(2)} />
          <Tab label="Giving Back" {...a11yProps(3)} />
          <Tab label="Support" {...a11yProps(4)} />
          <Tab label="Footer" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <HomeContent></HomeContent>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WhyUWPage />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UWInActionPage />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <GivingBackPage />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SupportPage />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Footer />
      </TabPanel>
    </div>
  );
}
