import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "react-router-dom";
import "./entry.css";

export default function Entry({ homeEntry, history }) {
  const [loading, set_loading] = useState(true);
  const [obj, set_obj] = useState({});
  useEffect(() => {
    set_obj(homeEntry ? homeEntry.data : {});
    if (homeEntry) {
      set_loading(false);
    } else {
      set_loading(true);
    }
  }, [homeEntry]);

  return (
    <LoadingOverlay active={loading} spinner text="Please wait...">
      <div
        className="entryContainer"
        style={{ backgroundImage: `url(${obj.img_url})` }}
      >
        <div className="innerEntry">
          <h2 className="entryTitle">{obj.title}</h2>
          <h6 className="entryContent">{obj.content}</h6>
          <Link to="/login">
            <Button
              variant="contained"
              size="large"
              className="mt-3 ubuntu-blue"
            >
              {obj.buttonText}
            </Button>
          </Link>
        </div>
      </div>
    </LoadingOverlay>
  );
}
