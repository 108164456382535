import {
    ADD_USER_FAIL,
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS, GET_INVITES_FAIL,
    GET_INVITES_REQUEST,
    GET_INVITES_SUCCESS,
    INVITE_USER_FAIL,
    INVITE_USER_REQUEST,
    INVITE_USER_SUCCESS,
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    SIGNUP_FAIL,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    USERS_FAIL,
    USERS_REQUEST,
    USERS_SUCCESS
} from "../actions/types";

const defaultProps = {
    loading: false,
    inviteId: null,
    invites: []
};

const userReducer = (state = defaultProps, action) => {
    switch (action.type) {
        case INVITE_USER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case INVITE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                inviteId: action.data.inviteId,
            };
        case INVITE_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };


        case GET_INVITES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_INVITES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                invites: action.data,
            };
        case GET_INVITES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state;
    }
}
export default userReducer;
