import React, { Component } from "react";
import {
  Alert,
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  senNotificationRegister,
  signupUser,
  getInvites,
} from "../../../actions";
import LoadingOverlay from "react-loading-overlay";
import { userBIdentityForInviteSignin } from "../../../helpers/util";
import { configure } from "enzyme";
import PhoneInput from "react-phone-input-2";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      message: "",
      userName: "",
      userEmail: "",
      userPassword: "",
      userPasswordConfirm: "",
      phoneNumber: "",
      confirmEmail: "",
    };
  }

  getInvites = (id) => {
    this.props
      .getInvites({
        FilterExpression: "#invite_id = :invite_id",
        ExpressionAttributeNames: {
          "#invite_id": "invite_id",
        },
        ExpressionAttributeValues: {
          ":invite_id": id,
        },
      })
      .then((reqponse) =>
        this.setState({
          userEmail: reqponse?.length > 0 && reqponse[0].invite_to_email,
          confirmEmail: reqponse?.length > 0 && reqponse[0].invite_to_email,
        })
      );
  };

  componentDidMount() {
    console.log(this.props);
    const user = localStorage.getItem("LOGGED_USER");
    let inviteId = this.props.location.state?.inviteId;
    if (inviteId) {
      this.getInvites(inviteId);
    }
    if (user) {
      this.props.history.push("/dashboard");
    }
  }
  checkPhone = () => {
    var isphone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      this.state.phoneNumber
    );
    if (!isphone) {
      this.setState({
        error: "Please enter a valid phone number",
        message: "",
      });
      return true;
    }
    return false;
  };

  checkPhonenumber = (number) => {
    let correct_phoneNumber = number;

    if (correct_phoneNumber) {
      if (!correct_phoneNumber.includes("+")) {
        correct_phoneNumber = "+" + correct_phoneNumber;
      }
    }
    console.log(correct_phoneNumber);

    return correct_phoneNumber;
  };

  createAccount = () => {
    const userBIdentityId = this.props.match.params.id;
    const {
      userName,
      userPassword,
      userPasswordConfirm,
      phoneNumber,
    } = this.state;
    let userEmail = this.state.userEmail.toLowerCase();
    if (
      userName &&
      userEmail &&
      userPassword &&
      userPasswordConfirm &&
      phoneNumber
    ) {
      if (userPassword != userPasswordConfirm) {
        this.setState({ error: "Password incorrect", message: "" });
      } else if (this.checkPhone()) {
      } else {
        this.props
          .signupUser({
            name: userName,
            email: userEmail,
            password: userPassword,
            user_role:
              userBIdentityId === userBIdentityForInviteSignin
                ? "user_b"
                : "user_a",
            phoneNumber: this.checkPhonenumber(phoneNumber),
            notifications: [],
          })
          .then((res) => {
            if (this.props.location.state?.inviteId) {
              if (this.state.confirmEmail === this.state.userEmail) {
                senNotificationRegister({
                  invite_id: this.props.location.state?.inviteId,
                });
              }
            }
            localStorage.setItem("LOGGED_USER", JSON.stringify(res));
            this.setState({ error: "", message: "Signup Successful!" });
            setTimeout(() => {
              this.props.history.push("/dashboard");
            }, 1000);
          })
          .catch((error) => {
            this.setState({ error, message: "" });
          });
      }
    } else {
      this.setState({ error: "Enter all input values", message: "" });
    }
  };

  render() {
    const {
      userName,
      userEmail,
      userPassword,
      userPasswordConfirm,
    } = this.state;
    const userBIdentityId = this.props.match.params.id;
    console.log(this.state);
    return (
      <LoadingOverlay
        active={this.props.user.loading}
        spinner
        text="Creating new account..."
      >
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="9" lg="7" xl="6">
                <Card className="mx-4">
                  <CardBody className="p-4">
                    <h1>Register</h1>
                    <p className="text-muted">Create your account</p>

                    {this.state.error && (
                      <Alert color="danger">{this.state.error}</Alert>
                    )}
                    {this.state.message && (
                      <Alert color="success">{this.state.message}</Alert>
                    )}

                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder="Username"
                        autoComplete="username"
                        value={userName}
                        onChange={(event) => {
                          this.setState({ userName: event.target.value });
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>@</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder="Email"
                        autoComplete="email"
                        value={userEmail}
                        onChange={(event) => {
                          this.setState({ userEmail: event.target.value });
                        }}
                      />
                    </InputGroup>
                    <PhoneInput
                      country={"us"}
                      value={this.state.phoneNumber}
                      onChange={(phone) => {
                        this.setState({ phoneNumber: phone });
                        console.log(phone);
                      }}
                    />
                    <InputGroup className="mb-3 mt-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        value={userPassword}
                        onChange={(event) => {
                          this.setState({ userPassword: event.target.value });
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder="Repeat password"
                        autoComplete="new-password"
                        value={userPasswordConfirm}
                        onChange={(event) => {
                          this.setState({
                            userPasswordConfirm: event.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                    <Button
                      color="success"
                      className="ubuntu-green"
                      block
                      onClick={this.createAccount}
                    >
                      Create Account
                    </Button>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        paddingTop: "16px",
                      }}
                    >
                      <Link
                        to={
                          "/login" +
                          (userBIdentityId ? "/" + userBIdentityId : "")
                        }
                      >
                        <Button color={"link"}>Login</Button>
                      </Link>
                    </div>
                  </CardBody>

                  {/*<CardFooter className="p-4">*/}
                  {/*  <Row>*/}
                  {/*    <Col xs="12" sm="6">*/}
                  {/*      <Button className="btn-facebook mb-1" block><span>facebook</span></Button>*/}
                  {/*    </Col>*/}
                  {/*    <Col xs="12" sm="6">*/}
                  {/*      <Button className="btn-twitter mb-1" block><span>twitter</span></Button>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</CardFooter>*/}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapDispatchToProps = {
  signupUser,
  getInvites,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
