import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import ReactPlayer from "react-player";
import "./FeaturesPage.css";
function FeaturesPage({ cartsDetails }) {
  const [obj, set_obj] = useState({});
  useEffect(() => {
    set_obj(cartsDetails ? cartsDetails.data : {});
  }, [cartsDetails]);

  return (
    <div className="featuresPage">
      <Grid container>
        <Grid
          container
          xs={12}
          lg={6}
          md={6}
          className="p-6 features_card relative"
          justify="center"
          alignItems="center"
          direction="column"
        >
         <div className="yazılar">
          <h1 className="features_card__h1 ">{obj.title_1}</h1>
          <p className="features_card__p">{obj?.content_1}</p>
          </div>
          <div className="homePage__videoPhone_bg"></div>
            <div className="homePage__videoPhone_iframe">
              <ReactPlayer
                url={obj.video_3}
                controls
                width={232}
                style={{ position: "relative", right: "6px", bottom: "-128px" }}
                height={448}
              />
            </div>
        </Grid>
        <Grid
          container
          xs={12}
          lg={6}
          md={6}
          className="p-6 features_card2 relative"
          justify="center"
          alignItems="center"
          direction="column"
        >
          <div className="yazılar">
          <h1 className="features_card__h1 white">{obj?.title_2}</h1>
          <p className="features_card__p">{obj?.content_2}</p>
          </div>
          <div className="homePage__videoPhone_bg"></div>
            <div className="homePage__videoPhone_iframe">
              <ReactPlayer
                url={obj?.video_2}
                controls
                width={232}
                style={{ position: "relative", right: "6px", bottom: "-128px" }}
                height={448}
              />
            </div>
        </Grid>
        <Grid
          container
          xs={12}
          md={6}
          lg={6}
          className="p-6 features_card3 relative"
          justify="center"
          alignItems="center"
          direction="column"
        >
          <div className="yazılar">
          <h1 className="features_card__h1 white">{obj?.title_3}</h1>
          <p className="features_card__p">{obj?.content_3}</p>
          </div>
          <div className="homePage__videoPhone_bg"></div>
            <div className="homePage__videoPhone_iframe">
              <ReactPlayer
                url={obj?.video_3}
                controls
                width={232}
                style={{ position: "relative", right: "6px", bottom: "-128px" }}
                height={448}
              />
            </div>
        </Grid>
        <Grid
          container
          xs={12}
          md={6}
          lg={6}
          className="p-6 features_card relative"
          justify="center"
          alignItems="center"
          direction="column"
        >
          <div className="yazılar">
          <h1 className="features_card__h1">{obj?.title_4}</h1>
          <p className="features_card__p">{obj?.content_4}</p>
          </div>
          <div className="homePage__videoPhone_bg"></div>
            <div className="homePage__videoPhone_iframe">
              <ReactPlayer
                url={obj?.video_4}
                controls
                width={232}
                style={{ position: "relative", right: "6px", bottom: "-128px" }}
                height={448}
              />
            </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default FeaturesPage;
