import {combineReducers} from 'redux';
import tasks from './taskReducer';
import user from './userReducer';
import invite from './inviteReducer';
import answer from './answerReducer';
import notifications from "./notifications"

export default combineReducers({
    tasks: tasks,
    user: user,
    invite: invite,
    answer: answer,
    notifications:notifications
});
