import React, { useState, useEffect } from "react";
import UpdateContent from "../UpdateContent";
import LoadingOverlay from "react-loading-overlay";
import HomeEntryImage from "../images/home_entry.png";
import HomeVideoImage from "../images/home_video.png";
import HomeCartImage from "../images/home_carts.png";
import VideoContent from "../videoUploadContent";
import Entry from "../homeEntry";
import { getCMS } from "../../../actions/index";
import VideoUpload from "../../../views/AnswerSubmission/VideoUpload";
export default function HomePage() {
  const [data, set_data] = useState([]);
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    Update();
  }, []);

  const Seperate = (name) => {
    let seperated = null;
    data.map((item) => {
      console.log(item.content_id === name);
      if (item.content_id === name) seperated = item;
    });

    return seperated;
  };

  const Update = () => {
    set_loading(true);
    getCMS()
      .then((r) => {
        set_loading(false);
        set_data(r.data);
      })
      .catch((e) => console.log(e));
  };
  console.log(Seperate("home_entry"));
  return (
    <LoadingOverlay active={loading} spinner text="Please wait...">
      <Entry
        inputs={[
          { name: "buttonText", placeholder: "Button Text" },
          { name: "title", placeholder: "title" },
          { name: "content", placeholder: "content" },
        ]}
        update={Update}
        url={HomeEntryImage}
        data={Seperate("home_entry")}
      />
      <VideoContent
        inputs={[{ name: "title", placeholder: "title" }]}
        update={Update}
        url={HomeVideoImage}
        data={Seperate("home_video")}
      />
      <UpdateContent
        inputs={[
          { name: "title", placeholder: "Main Title" },
          { name: "title_1", placeholder: "Title 1" },
          { name: "content_1", placeholder: "Content 1" },
          { name: "title_2", placeholder: "Title 2" },
          { name: "content_2", placeholder: "Content 2" },
          { name: "title_3", placeholder: "Title 3" },
          { name: "content_3", placeholder: "Content 3" },
          { name: "title_4", placeholder: "Title 4" },
          { name: "content_4", placeholder: "Content 4" },
        ]}
        update={Update}
        url={HomeCartImage}
        data={Seperate("home_carts")}
      />
    </LoadingOverlay>
  );
}
