import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import {
  loginUser,
  senNotificationRegister,
  socialLogin,
  switchUserAccount,
} from "../../../actions";
import { connect } from "react-redux";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { userBIdentityForInviteSignin } from "../../../helpers/util";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      message: "",
      userEmail: "",
      userPassword: "",
    };
  }

  componentDidMount() {
    const user = localStorage.getItem("LOGGED_USER");

    if (user) {
      this.props.history.push("/dashboard");
    }
  }

  login = () => {
    let userPassword = this.state.userPassword;
    let userEmail = this.state.userEmail.toLowerCase().replace(/\s/g, "");
    if (userEmail && userPassword) {
      this.props
        .loginUser({
          email: userEmail,
          password: userPassword,
        })
        .then((res) => {
          this.loginSuccess(res);
        })
        .catch((error) => {
          this.setState({
            error: "Email or Password does not match.",
            message: "",
          });
        });
    } else {
      this.setState({ error: "Enter all input values", message: "" });
    }
  };

  responseFacebook = (response) => {
    console.log("Facebook Response " + JSON.stringify(response));

    const { name, email, userID } = response;
    if (name && userID) {
      const userBIdentityId = this.props.match.params.id;

      this.props
        .socialLogin({
          name,
          email: email ? email : userID,
          social_user_id: userID,
          login_provider: "facebook",
          user_role:
            userBIdentityId === userBIdentityForInviteSignin
              ? "user_b"
              : "user_a",
        })
        .then((res) => {
          if (this.props.location.state?.inviteId) {
            senNotificationRegister({
              invite_id: this.props.location.state?.inviteId,
            });
          }
          this.loginSuccess(res);
        })
        .catch((error) => {
          this.setState({ error, message: "" });
        });
    } else {
      this.setState({ error: "Facebook login error", message: "" });
    }
  };

  responseGoogle = (response) => {
    console.log("Google Response " + JSON.stringify(response));
    if (response.error) {
      this.setState({ error: response.error, message: "" });
    } else {
      const { name, email, googleId } = response.profileObj;
      if (name && googleId) {
        const userBIdentityId = this.props.match.params.id;

        this.props
          .socialLogin({
            name,
            email: email ? email : googleId,
            social_user_id: googleId,
            login_provider: "google",
            user_role:
              userBIdentityId === userBIdentityForInviteSignin
                ? "user_b"
                : "user_a",
          })
          .then((res) => {
            if (this.props.location.state?.inviteId) {
              senNotificationRegister({
                invite_id: this.props.location.state?.inviteId,
              });
            }
            this.loginSuccess(res);
          })
          .catch((error) => {
            this.setState({ error, message: "" });
          });
      } else {
        this.setState({ error: "Google login error", message: "" });
      }
    }
  };

  loginSuccess = (res) => {
    const userBIdentityId = this.props.match.params.id;
    if (userBIdentityId === userBIdentityForInviteSignin) {
      this.props
        .switchUserAccount({
          user_id: res.user_id,
          user_role: "user_b",
          added_by_user: res.added_by_user,
        })
        .then((userRes) => {
          this.loginSuccessAction(userRes);
        })
        .catch(() => {
          this.loginSuccessAction(res);
        });
    } else {
      this.loginSuccessAction(res);
    }
  };

  loginSuccessAction = (res) => {
    localStorage.setItem("LOGGED_USER", JSON.stringify(res));
    this.setState({ error: "", message: "Logged in successfully" });
    setTimeout(() => {
      this.props.history.push("/dashboard");
    }, 1000);
  };

  render() {
    let inviteId = this.props.location.state?.inviteId;
    const { userEmail, userPassword } = this.state;
    const userBIdentityId = this.props.match.params.id;
    let prm = userBIdentityId ? "/" + userBIdentityId : "";
    const location = {
      pathname: `/register${prm}`,
      state: { inviteId: inviteId },
    };
    return (
      <LoadingOverlay
        active={this.props.user.loading}
        spinner
        text="Validating..."
      >
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="5">
                <CardGroup>
                  <Card className="p-4">
                    <CardBody>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>

                      {this.state.error && (
                        <Alert color="danger">{this.state.error}</Alert>
                      )}
                      {this.state.message && (
                        <Alert color="success">{this.state.message}</Alert>
                      )}

                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-envelope"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder="Email"
                          autoComplete="username"
                          value={userEmail}
                          onChange={(event) => {
                            this.setState({ userEmail: event.target.value });
                          }}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          value={userPassword}
                          onChange={(event) => {
                            this.setState({ userPassword: event.target.value });
                          }}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="5">
                          <Button
                            type={"submit"}
                            className="px-4 ubuntu-blue"
                            onClick={this.login}
                          >
                            Login
                          </Button>
                        </Col>
                        <Col xs="6">
                          <Link to="/reset-password" style={{ width: "100%" }}>
                            <Button className="px-4 ubuntu-blue">
                              Forgot Password?
                            </Button>
                          </Link>
                        </Col>

                        {/*<Col xs="6" className="text-right">*/}
                        {/*    <Button color="link" className="px-0">Forgot password?</Button>*/}
                        {/*</Col>*/}
                      </Row>

                      <Row className={"mt-2 d-md-down-block d-lg-none"}>
                        <Col xs="12">
                          <Link to={location}>
                            <Button
                              size="lg"
                              type={"button"}
                              color="secondary"
                              style={{ width: "100%" }}
                            >
                              Register
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FacebookLogin
                            appId="1260286061005574"
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={this.responseFacebook}
                            onFailure={this.responseFacebook}
                            size={"small"}
                            redirectUri={process.env.REACT_APP_DOMAIN}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <GoogleLogin
                            clientId="118407021425-oinpssdhp68rlp9ll58jpp0v5rggh0co.apps.googleusercontent.com"
                            buttonText="LOGIN WITH GOOGLE"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            className={"login-with-google"}
                            cookiePolicy={"single_host_origin"}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col xs="12">
                          <p>
                            Don't have an account?{" "}
                            <Link
                              to={location}
                              style={{ width: "100%", color: "#3f47cc" }}
                            >
                              Sign Up
                            </Link>
                          </p>
                        </Col>

                        {/*<Col xs="6" className="text-right">*/}
                        {/*    <Button color="link" className="px-0">Forgot password?</Button>*/}
                        {/*</Col>*/}
                      </Row>
                    </CardBody>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapDispatchToProps = {
  loginUser,
  socialLogin,
  switchUserAccount,
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
