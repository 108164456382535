import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import "./GivingBack.css";
function PrivacyPage({ title, dataTexts }) {
  const [texts, set_texts] = useState({});

  useEffect(() => {
    set_texts(dataTexts ? dataTexts.data : {});
  }, [dataTexts]);
  return (
    <div className="securityPage mt-6">
      <div className="securityPage__container">
        <div
          className="securityPage__container__top"
          style={{
            backgroundColor: "#3239ab",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text-center white ">{title}</h1>
        </div>
        <div className="securityPage__container__bottom">
          <div className="securityPage__container__bottom__container">
            <div className="securityPage__container__bottom__container__inner">
              <div className="securityPage__container__bottom__container__inner__texts">
                <h1>Privacy Policy</h1>
                <p>
                  This privacy policy ("Policy") sets out how we, UW
                  Partnership. ("UW"), makers of the Ubuntu.World video sharing
                  advanced web app (the “Ubuntu World AWA”) and together with
                  the Ubuntu World AWA, the “Apps,” use and protect the personal
                  information of people who use the Apps or who visit Ubuntu
                  World websites such as www.ubuntu.world (the “Ubuntu World
                  Website”). We will refer to the Ubuntu World AWA, and Ubuntu
                  World Website collectively as the "Service" or “Ubuntu World.”
                </p>
                <h3>
                  <a name="_8k15eee9131"></a>
                  <strong>Privacy Principles</strong>
                </h3>
                <p>
                  UW has two fundamental principles when it comes to personal
                  information:
                </p>
                <p>
                  1. We don't sell your personal information or use it to show
                  you ads.
                </p>
                <p>
                  2. We only store the personal information that we need to
                  operate our Service so you can communicate with the people you
                  choose.
                </p>
                <p>
                  This Policy applies to anyone who accesses the Service. By
                  visiting the Website and/or using the Apps or Service, you
                  acknowledge that the collection, use, and sharing of your
                  information will take place as described in this Policy.
                </p>
                <p>
                  So we are clear about the terminology we are using, when we
                  use the phrase "Personal Information" in this Privacy Policy,
                  we mean information that is capable of being associated with
                  or could reasonably be linked with you, such as your name,
                  email, or mobile number.
                </p>
                <h3>
                  <a name="_izvq0oeew8mw"></a>
                  <strong>What we collect</strong>
                </h3>
                <p>
                  Ubuntu World is a communication service. To operate the
                  Service, we may collect Personal Information you choose to
                  provide us.
                </p>
                <h4>
                  <a name="_jz2h16gb0fuk"></a>
                  <strong>1. Ubuntu World AWA</strong>
                </h4>
                <h5>
                  <a name="_7suae0ehnr7o"></a>
                  <strong>a. Basic Account Data</strong>
                </h5>
                <p>
                  To create a Ubuntu World account (“Account”), you provide your
                  mobile number, your name and your email address, and may
                  choose to provide your photo, birth day and birth month. Only
                  other registered Ubuntu World users that:
                </p>
                <p>
                  ● already have your phone number in their phone’s address
                  book,
                </p>
                <p>● are in a group with you, or</p>
                <p>● you invited to Ubuntu World</p>
                <p>
                  may see your name, photo, mobile number, email, birth day
                  and/or birth month. Note that users who have you in their
                  contacts will see you by the name they’ve saved and not by the
                  name you provided to Ubuntu World. For example, your spouse
                  will still see you as “Darling” when they use the app if
                  that’s how you appear in their phone’s address book. If you
                  choose to provide them, we collect your birthday and month so
                  people you know get reminded when it’s your birthday.
                </p>
                <h5>
                  <a name="_cjhbbdme5wc9"></a>
                  <strong>b. Messages and Videos</strong>
                </h5>
                <p>
                  Ubuntu World is a cloud service. We may collect and store the
                  content you create, including videos (or “messages”), photos,
                  short video clips you share with selected contact 6sand text
                  messages. We store this Personal Information on our servers so
                  that you can access your data anytime from any of your devices
                  that have the Ubuntu World AWA without having to rely on
                  third-party backups or use up your phone’s memory. Our servers
                  are located in the United States.
                </p>
                <h5>
                  <a name="_hf0uakr9hby4"></a>
                  <strong>c. Contacts</strong>
                </h5>
                <p>
                  You may choose to provide us with your friends' contact
                  information as stored on your phone. Ubuntu World uses mobile
                  phone numbers and email addresses as unique identifiers so
                  that it is easy for you to find and communicate with other
                  people you know. We ask your permission before syncing your
                  contacts, and you can still use the app without giving it
                  access to your contacts (though you will have a harder time
                  finding people you know who also use Ubuntu World).
                </p>
                <p>
                  We store your contacts on our servers in order to notify you
                  as soon as one of your contacts creates a Ubuntu World Account
                  and to properly display their names. We store their name,
                  phone number(s), email address(es), and/or birth day and month
                  for this to work.
                </p>
                <p>
                  Our automatic algorithms also use this data to suggest which
                  of your contacts you might like to communicate with on Ubuntu
                  World. We use this information to suggest people to invite to
                  join Ubuntu World but we do not contact anyone in your phone’s
                  contact list without your permission. Other than our Service
                  Providers that we describe below, we do not share your
                  contacts with third parties unless required to do so in
                  response to valid legal requests, such as through a court
                  order or search warrant.
                </p>
                <h4>
                  <a name="_cb2s65ml97hq"></a>
                  <strong>2. User Support</strong>
                </h4>
                <p>
                  If you contact Ubuntu World support, any Personal Information
                  you may share with us is used only for the purposes of
                  researching the issue and contacting you about your request.
                </p>
                <h4>
                  <a name="_wtj0e4l85uen"></a>
                  <strong>3. Additional Technical Information</strong>
                </h4>
                <p>
                  We use third-party analytics Service Providers (as more fully
                  described below, and including Google Analytics and
                  amplitude.com) to automatically log information about you and
                  your computer or mobile device, and how you interact with the
                  Service. For example, when you access the Service, we may log‎
                  your operating system type,‎ browser type and‎ language,‎ the
                  pages you viewed,‎ how long you spent on a page,‎ access
                  times,‎ Internet protocol (IP) address, your mobile device ID,
                  mobile device serial number, unique user ID, wireless carrier,
                  and information about your use of and actions on the Service.
                  Ubuntu World collects this additional technical data to
                  analyze usage trends and give us more information about how
                  people use the Service to better the user experience.
                </p>
                <h5>
                  <a name="_1plbc3wv8fz9"></a>
                  <strong>3.1 Cookies</strong>
                </h5>
                <p>
                  The only cookies we use are those to operate and provide our
                  Service on the web. We do not use cookies for profiling or
                  advertising. The cookies we use are small text files that
                  allow us to provide and customize our Service, and in doing
                  so, provide you with a better user experience. Your browser
                  should allow you to control these cookies, including whether
                  or not to accept them and how to remove them. You may choose
                  to block cookies with your web browser and continue using the
                  Service via the Ubuntu World AWA and Website without
                  interruption.
                </p>
                <h5>
                  <a name="_kn1gvisq26gb"></a>
                  <strong>3.2 Web Beacons</strong>
                </h5>
                <p>
                  We may log information using digital images called web beacons
                  on our Service or in our emails. We use web beacons to manage
                  cookies, count visits, and to learn which emails are read and
                  used by our users and which are not. We also use web beacons
                  to tell if you open or act on our emails. Similar to cookies,
                  we use web beacons to improve your experience with our
                  Service.
                </p>
                <p>
                  Tracking Options and California Do Not Track Disclosures. You
                  are welcome to adjust your browser or operating system
                  settings to limit this tracking or to decline cookies and
                  doing so will not change your experience in the Ubuntu World
                  AWA or Website.
                </p>
                <p>
                  Check the "Help" menu of your browser or operating system to
                  learn how to change your tracking settings or cookie
                  preferences. For example:
                </p>
                <p>With Chrome:</p>
                <p>1. At the top right, click More Settings.</p>
                <p>2. At the bottom, click Advanced.</p>
                <p>3. Under "Privacy and security," turn on "Do Not Track."</p>
                <p>
                  With Firefox, follow the instructions
                  <a href="https://support.mozilla.org/en-US/kb/how-do-i-turn-do-not-track-feature/">
                    here
                  </a>
                  .
                </p>
                <p>
                  <strong>Managing your Information</strong>
                </p>
                <p>
                  You can manage your Personal Information in the Apps’
                  Settings.
                </p>
                <h3>
                  <a name="_l8ya98gozogj"></a>
                  <strong>Use of Personal Information</strong>
                </h3>
                <p>
                  We use the information that we have about you to operate,
                  provide, support, personalize, and make our Service more
                  relevant and useful to you and others.
                </p>
                <p>
                  <strong>No Ads</strong>
                </p>
                <p>
                  We don't use your Personal Information for ad targeting.
                  Ubuntu World only stores the Personal Information it needs to
                  operate the Service.
                </p>
                <p>
                  We may use the Personal Information we collect from and about
                  you to:
                </p>
                <p>● Operate, maintain, and improve our Service;</p>
                <p>
                  ● Allow you to find and connect with your friends (when
                  instructed by you);
                </p>
                <p>
                  ● Let your friends know about your activity on the Service;
                </p>
                <p>
                  ● Analyze usage and trends and personalize and improve the
                  Service and your experience using the Service;
                </p>
                <p>
                  ● Respond to your comments and questions and provide customer
                  service;
                </p>
                <p>
                  ● Send information including confirmations, invoices,
                  technical notices, updates, security alerts, and support and
                  administrative messages;
                </p>
                <p>
                  ● Send marketing emails that promote the use of Ubuntu World
                  from which you can opt-out as described below;
                </p>
                <p>
                  ● Protect, investigate, and deter against fraudulent,
                  unauthorized, or illegal activity; and
                </p>
                <p>
                  ● Provide and deliver products and services that you request.
                </p>
                <p>
                  Our marketing emails tell you how to opt-out or unsubscribe.
                  If you opt-out of marketing emails, we may still send you
                  non-marketing emails and notifications, which may include
                  information about your account, our business dealings with
                  you, and updates regarding our policies.
                </p>
                <h3>
                  <a name="_gw0akbozz72"></a>
                  <strong>Sharing of Personal Information</strong>
                </h3>
                <p>
                  We may share the Personal Information we collect from and
                  about you (1) with other App users, according to your account
                  preferences and consistent with our Terms of Service; (2) for
                  legal, protection, and safety purposes; (3) to comply with
                  laws and in response to lawful requests and legal processes;
                  (4) to protect the rights and property of UW Partnership., our
                  agents, customers, and others, including by enforcing our
                  agreements, policies, and Terms of Service; and (5) with those
                  who need it to do work for us (our Service Providers, as
                  defined below).
                </p>
                <h4>
                  <a name="_c4utwx71ikfr"></a>
                  <strong>Other Ubuntu World Users</strong>
                </h4>
                <p>
                  Other users of our Service with whom you choose to communicate
                  will be given some of your Personal Information. These other
                  users may be located anywhere in the world, including outside
                  the European Economic Area (“EEA”). Note that by entering into
                  the Terms of Service and choosing to communicate with such
                  other users of the Apps, you are instructing us, as part of
                  our contract with you, to transfer your Personal Information,
                  on your behalf, to those users in accordance with this Privacy
                  Policy. We employ all appropriate technical and organizational
                  measures to ensure a level of security for your Personal
                  Information that is appropriate to the risk.
                </p>
                <p>
                  If Ubuntu World or substantially all of our assets are
                  acquired by a third party, Personal Information held by us
                  about our users will be one of the transferred assets. Such
                  third parties will be required to treat that Personal
                  Information in a manner consistent with this Policy.
                </p>
                <h4>
                  <a name="_jrfi9do0se4l"></a>
                  <strong>Service Providers</strong>
                </h4>
                <p>
                  We work with third parties to provide some of our Service
                  ("Service Providers"). For example, we use Third-Party SMS
                  Providers to send a verification code to your phone number
                  when you register on the Ubuntu World AWA. Service Providers
                  will have access to your Personal Information needed to
                  perform their business functions, but may not use or share
                  that Personal Information for purposes outside the scope of
                  their functions related to the Service.
                </p>
                <h4>
                  <a name="_rdfvp81jget4"></a>
                  <strong>Our Commitment towards Children's Privacy</strong>
                </h4>
                <p>
                  We do not direct the Service to, nor do we knowingly collect
                  any Personal Information from children under 13. Children
                  under 13 are not eligible to use the Service. If we learn that
                  a user is under 13 years of age, we will take steps to remove
                  that user's Personal Information from our databases and to
                  prevent that user from utilizing the Service.
                </p>
                <h3>
                  <a name="_ph6fmrvyiv2n"></a>
                  <strong>Notification Choices</strong>
                </h3>
                <p>
                  By default, Ubuntu World sends you emails and notifications
                  regarding activities on the Service, e.g., when you receive a
                  message from one of your contacts.
                </p>
                <p>
                  We may also communicate with you via informational,
                  transactional, or marketing emails and/or text messages.
                </p>
                <p>
                  You may choose to unsubscribe from emails by clicking
                  “Unsubscribe” from the email or in the Ubuntu World AWA by
                  going to Settings and selecting the “Send me fewer emails”
                  option and unselecting whichever notifications you do not want
                  to receive.
                </p>
                <p>
                  If you unsubscribe, we may still need to send you
                  non-marketing emails about your account or our business
                  dealings with you (e.g., emails regarding AppStore or Google
                  Play subscriptions you have with us).
                </p>
                <p>
                  We may also send you text notifications. You may opt out of
                  receiving text messages by replying to the last message
                  received with “STOP”.
                </p>
                <p>
                  You may also send requests about your Personal Information
                  ubuntu.world/contact. You can also request to change your
                  contact choices, opt-out of our sharing with others, and
                  update your Personal Information.
                </p>
                <h3>
                  <a name="_u3l6xfh8hio"></a>
                  <strong>Links to Other Sites</strong>
                </h3>
                <p>
                  The Service may contain links to other third-party websites or
                  applications. For example, we allow you to forward your
                  messages to YouTube.com. Any information you provide on those
                  sites is subject to that third party's privacy policy. Our
                  Policy does not apply to such sites, and we are not
                  responsible for the privacy and security practices and
                  policies of those third-party sites or applications.
                </p>
                <h3>
                  <a name="_urg2xo8xnfv1"></a>
                  <strong>Security of your Personal Information</strong>
                </h3>
                <p>
                  We are committed to protecting the security of your Personal
                  Information. We have taken certain physical, administrative,
                  and technical steps to help safeguard the information we
                  collect from and about you. While we take steps to help ensure
                  the integrity and security of our network and systems, we
                  cannot guarantee our security measures.
                </p>
                <h3>
                  <a name="_a6xcemv0jhhk"></a>
                  <strong>
                    California Consumer Privacy Act (CCPA) and General Data
                    Protection Regulation (GDPR)
                  </strong>
                </h3>
                <p>
                  The CCPA and GDPR provides rights to certain individuals
                  located in California and the European Union.{" "}
                  <strong>We apply these rights to all of our users</strong>.
                  You can contact us about these rights at ubuntu.world/contact.
                </p>
                <h4>
                  <a name="_jhn16obrv91z"></a>
                  <strong>CCPA</strong>
                </h4>
                <p>
                  We do not disclose Personal Information beyond what is
                  described in this Policy. All our users, including those in
                  California, have the following rights.
                </p>
                <p>
                  ● Right to access: You have the right to know and see what
                  data we have collected about you over the past 12 months
                  including:
                </p>
                <div style={{ paddingLeft: 30 }}>
                  <p>
                    ○ categories of personal information we have collected about
                    you;
                  </p>
                  <p>
                    ○ categories of sources from which the personal information
                    is collected;
                  </p>
                  <p>
                    ○ the business or commercial purpose for collecting your
                    personal information;
                  </p>
                  <p>
                    ○ the categories of third parties with whom we have shared
                    your personal information; and
                  </p>
                  <p>
                    ○ the specific pieces of personal information we have
                    collected about you.
                  </p>
                </div>
                <p>
                  ● Right to deletion: You have the right to request that we
                  delete in certain circumstances the personal information we
                  have collected from you.
                </p>
                <p>
                  ● Right not to be discriminated against: You have the right
                  not to be discriminated against for exercising any of these
                  rights.
                </p>
                <p>
                  To request access to or deletion of your personal information,
                  or to exercise any other data rights under the CCPA, please
                  contact us at ubuntu.world/contact.
                </p>
                <h3>
                  <a name="_edi64718jxyb"></a>
                  <strong>General Data Protection Regulation (GDPR)</strong>
                </h3>
                <p>
                  We, UW, are the data controller of your Personal Information.
                  Our contact information can be found at the end of this
                  Policy.
                </p>
                <h4>
                  <a name="_k4wtgk8utbfy"></a>
                  <strong>Information according to GDPR Article 27</strong>
                </h4>
                <p>
                  UW Partnership is a company located outside of the European
                  Union. In order to comply with GDPR Article 27,{" "}
                  <a href="https://gdpr-rep.eu/">GDPR-Rep.eu</a> has been
                  engaged as our representative in the European Union. If you
                  want to make use of your data privacy rights, please visit the{" "}
                  <a href="https://gdpr-rep.eu/gdpr-req/14211202">
                    EU privacy dashboard
                  </a>
                  or contact us at ubuntu.world/contact.
                </p>
                <p>
                  We also have an appointed Data Protection Officer, who can be
                  contacted at ubuntu.world/contact.
                </p>
                <p>
                  All our users, including those in the European Economic Area
                  (EEA), have the following rights, about which you can contact
                  us at ubuntu.world/contact.
                </p>
                <p>
                  1. Right to access: the right to request certain information
                  about, access to and copies of the Personal Information about
                  you that we are holding;
                </p>
                <p>
                  2. Right to request that we rectify, limit, cease processing
                  or erase information we hold about you in certain
                  circumstances;
                </p>
                <p>
                  3. Right to data portability: the right to request that we
                  return any information you have provided in a structured,
                  commonly used, and machine-readable format, or that we send it
                  directly to another company, where technically feasible;
                </p>
                <p>
                  4. Right to object: the right to object to our use of your
                  Personal Information including where we use it for our
                  legitimate interests or, if applicable, for marketing
                  purposes; and
                </p>
                <p>
                  5. Right to lodge a complaint about us to a supervisory body
                  in your country of work or residence (for instance, the
                  Information Commissioner’s Office in the UK).
                </p>
                <h4>
                  <a name="_clyf4f7bgen"></a>
                  <strong>Legal Basis for Processing</strong>
                </h4>
                <p>
                  We process your Personal Information on the ground that such
                  processing is necessary to further our legitimate interests
                  (including: (1) providing an effective and innovative Service
                  to our users; and (2) to detect, prevent or otherwise address
                  fraud or security issues in respect of our provision of the
                  Service), unless those interests are overridden by your
                  interest or fundamental rights and freedoms that require
                  protections of Personal Information.
                </p>
                <p>
                  We also process your Personal Information where it is
                  necessary on the basis of performing under our contract with
                  you in accordance with our Terms of Service, or in order to
                  create a contract with you.
                </p>
                <p>
                  Automated decision making: Our software operates an algorithm
                  to determine which of your contacts may be most relevant to
                  you, who you might wish to connect with and/or invite to the
                  Service.
                </p>
                <p>
                  Recipients of Personal Information and transfers to third
                  countries:as set out in the sections above called Sharing of
                  Personal Information and Service Providers, we share your
                  Personal Information with certain third party providers in the
                  United States, and such transfers are subject to appropriate
                  safeguards.
                </p>
                <h3>
                  <a name="_4ek33e65k5ay"></a>
                  <strong>Retention</strong>
                </h3>
                <p>
                  We may retain the Personal Information you provide as long as
                  you have an Account.
                </p>
                <h4>
                  <a name="_4b2ubal9259q"></a>
                  <strong>a. Ubuntu World AWA</strong>
                </h4>
                <p>
                  If you delete your Account, your Personal Information and any
                  videos you recorded will be deleted plus you will be removed
                  from any Ubuntu World groups; your name, profile picture,
                  messages and information you have shared with other users
                  through Ubuntu World will remain accessible to them as
                  described in the Terms of Service. If you delete your Account
                  and had earlier synced your phone’s contact list with Ubuntu
                  World, the contact data and names of any of your contacts who
                  have not registered on Ubuntu World will also be deleted from
                  our servers.
                </p>
                <p>
                  You can also permanently delete any invite you create, and
                  that is still available to you in the Ubuntu World AWA by
                  pressing on the deleting the invite. Doing so will permanently
                  remove it from our servers and the Ubuntu World accounts of
                  whomever you sent it to.
                </p>
                <p>
                  Messages you have shared with other users through Ubuntu World
                  will remain accessible to them as described in the Terms of
                  Service.
                </p>
                <h3>
                  <a name="_ydzwcg47m3zd"></a>
                  <strong>Contact Information</strong>
                </h3>
                <p>
                  We welcome your comments or questions about this Policy. You
                  may contact us at ubuntu.world/contact.
                </p>
                <h3>
                  <a name="_bvoztyxc4abj"></a>
                  <strong>Changes to Privacy Policy</strong>
                </h3>
                <p>
                  We may modify this Policy from time to time. If we make any
                  changes, we will change the Last Updated date below. We also
                  may provide additional notice, as applicable, depending on the
                  type of change. If you object to any changes, you may stop
                  using the Service. Your continued use of the Service after we
                  publish or otherwise provide notice about our changes to the
                  Policy means that you have read and understood the updated
                  Policy.
                </p>
                <p>Last Updated on December, 22, 2020.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPage;
