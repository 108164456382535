import React, { Component, useEffect, useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Amplify, { API } from "aws-amplify";
import awsconfig from "./aws-exports";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import { AdminLogin, AdminDash } from "./views/Pages";
import CMS from "../src/admin/cms/index";
import T from "../src/admin/dashboard/table";
import T2 from "../src/admin/dashboard/table2";

import Header from "../src/views/header/header";
import Homepage from "./views/Pages/HomePage";
import Footer from "./views/Pages/HomePage/components/Footer/Footer";
import Contact from "./views/Pages/Contact";
import Faqs from "./views/Pages/FAQS/index";
import Features from "./views/Pages/FeaturesPage/FeaturesPage";
import Security from "./views/Pages/SecurityPage/SecurityPage";
import { getCMS } from "./actions/index";
import GivingBack from "./views/Pages/GivingBackPage/GivingBack";
import PrivacyPage from "./views/Pages/PrivacyPage/PrivacyPage";
import LoadingOverlay from "react-loading-overlay";
import Reset from "./views/Pages/Login/Reset";
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
Amplify.configure(awsconfig);

function App() {
  const [data, set_data] = useState([]);
  const [loadingss, set_loading] = useState(true);

  useEffect(() => {
    Update();
  }, []);
  const Update = () => {
    getCMS()
      .then((r) => {
        set_data(r.data);
        set_loading(false);
      })
      .catch((e) => console.log(e));
  };
  const Seperate = (name) => {
    let seperated = null;
    data.map((item) => {
      if (item.content_id === name) seperated = item;
    });

    return seperated;
  };
  console.log = function () {};
  return (
    <LoadingOverlay active={loadingss} spinner text="Please wait...">
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/reset-password"
              name="Login Page"
              render={(props) => <Reset {...props} />}
            />
            <Route
              exact
              path="/login/:id"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/register/:id"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/adminlogin"
              name="Admin Login Page"
              render={(props) => <AdminLogin {...props} />}
            />
            <Route
              exact
              path="/admin"
              name="Admin Page"
              render={(props) => <AdminDash {...props} />}
            />
            <Route
              exact
              path="/admin/admin-users"
              name="Admin Page1"
              render={(props) => <AdminDash {...props} />}
            />
            <Route
              exact
              path="/admin/admin-cms"
              name="Admin Page2"
              render={(props) => <AdminDash {...props} />}
            />
            <Route
              exact
              path="/admin/admin-invites"
              name="Admin Page3"
              render={(props) => <AdminDash {...props} />}
            />
            <Route
              path="/home"
              name="landingPage"
              render={(props) => [
                <Header {...props} />,
                <Homepage
                  {...props}
                  homeEntry={Seperate("home_entry")}
                  homeVideo={Seperate("home_video")}
                  homeCart={Seperate("home_carts")}
                ></Homepage>,
                <Footer data={Seperate("footer")} {...props} />,
              ]}
            />
            <Route
              path="/uw-in-action"
              exact
              name="landingPage"
              render={(props) => [
                <Header {...props} />,
                <Features
                  cartsDetails={Seperate("uwactions_carts")}
                  {...props}
                ></Features>,
                <Footer data={Seperate("footer")} {...props} />,
              ]}
            />
            <Route
              path="/giving-back"
              exact
              name="landingPage"
              render={(props) => [
                <Header {...props} />,
                <GivingBack
                  title={"Giving Back"}
                  dataCard={Seperate("givingback_carts")}
                  dataTexts={Seperate("givingback_entry")}
                  {...props}
                ></GivingBack>,
                <Footer data={Seperate("footer")} {...props} />,
              ]}
            />
            <Route
              path="/privacy"
              exact
              name="landingPage"
              render={(props) => [
                <Header {...props} />,
                <PrivacyPage
                  title={"Privacy"}
                  dataTexts={Seperate("privacy")}
                  {...props}
                ></PrivacyPage>,
                <Footer data={Seperate("footer")} {...props} />,
              ]}
            />

            <Route
              path="/why-uw"
              exact
              name="landingPage"
              render={(props) => [
                <Header {...props} />,
                <Security
                  {...props}
                  title="Why UW?"
                  dataCard={Seperate("whyuw_carts")}
                  dataTexts={Seperate("whyuw_entry")}
                ></Security>,
                <Footer data={Seperate("footer")} {...props} />,
              ]}
            />

            <Route
              path="/contact"
              exact
              render={(props) => [
                <Header {...props} />,
                <Contact {...props}></Contact>,
                <Footer data={Seperate("footer")} {...props} />,
              ]}
            />
            <Route
              path="/faqs"
              exact
              render={(props) => [
                <Header {...props} />,
                <Faqs questions={Seperate("support")} {...props}></Faqs>,
                <Footer data={Seperate("footer")} {...props} />,
              ]}
            />

            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>

          <NotificationContainer />
        </React.Suspense>
      </HashRouter>
    </LoadingOverlay>
  );
}

export default App;
