import React, { useState, useEffect } from "react";
import UpdateContent from "../UpdateContent";
import LoadingOverlay from "react-loading-overlay";
import SupportImage from "../images/support.png";
import Support from "../FaqUpdate"

import { getCMS } from "../../../actions/index";
export default function SupportPage() {
  const [data, set_data] = useState([]);
  const [loading, set_loading] = useState(true);

  useEffect(() => {
    Update();
  }, []);
  
 
  const Seperate = (name) => {
    let seperated = null;
    data.map((item) => {
      console.log(item.content_id === name);
      if (item.content_id === name) seperated = item;
    });
console.log("separeted",seperated)
    return seperated
  };

  const Update = () => {
    set_loading(true);
    getCMS()
      .then((r) => {
        console.log("response",r)
       
        set_data(r.data);
        set_loading(false);
      })
      .catch((e) => console.log(e));
  };
 console.log("datalar",Seperate("support"))
  return (
    <LoadingOverlay active={loading} spinner text="Please wait...">
      <Support
        inputs={Seperate("support")?Seperate("support").data:[]}
        update={Update}
        url={SupportImage}
       
      />
    </LoadingOverlay>
  );
}
