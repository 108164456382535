export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";

export const VERIFY_PHONE_SUCCESS = "VERIFY_PHONE_SUCCESS";
export const VERIFY_PHONE_REQUEST = "VERIFY_PHONE_REQUEST";

export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const DROPDOW_CHANGE = "DROPDOW_CHANGE";
export const SOCIAL_LOGIN_REQUEST = "SOCIAL_LOGIN_REQUEST";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAIL = "SOCIAL_LOGIN_FAIL";
export const UPDATE_CHANGE = "UPDATE_CHANGE";

export const UPDATE_REQUEST = "UPDATE_REQUEST";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";

export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAIL = "USERS_FAIL";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAIL = "INVITE_USER_FAIL";

export const GET_INVITES_REQUEST = "GET_INVITES_REQUEST";
export const GET_INVITES_SUCCESS = "GET_INVITES_SUCCESS";
export const GET_INVITES_FAIL = "GET_INVITES_FAIL";

export const QUESTION_SUBMIT_REQUEST = "QUESTION_SUBMIT_REQUEST";
export const QUESTION_SUBMIT_SUCCESS = "QUESTION_SUBMIT_SUCCESS";
export const QUESTION_SUBMIT_FAIL = "QUESTION_SUBMIT_FAIL";

export const QUESTION_GET_REQUEST = "QUESTION_GET_REQUEST";
export const QUESTION_GET_SUCCESS = "QUESTION_GET_SUCCESS";
export const QUESTION_GET_FAIL = "QUESTION_GET_FAIL";

export const SUBMIT_ANSWER_REQUEST = "SUBMIT_ANSWER_REQUEST";
export const SUBMIT_ANSWER_SUCCESS = "SUBMIT_ANSWER_SUCCESS";
export const SUBMIT_ANSWER_FAIL = "SUBMIT_ANSWER_FAIL";

export const GET_ANSWERS_REQUEST = "GET_ANSWERS_REQUEST";
export const GET_ANSWERS_SUCCESS = "GET_ANSWERS_SUCCESS";
export const GET_ANSWERS_FAIL = "GET_ANSWERS_FAIL";

export const SWITCH_USER_ACCOUNT_REQUEST = "SWITCH_USER_ACCOUNT_REQUEST";
export const SWITCH_USER_ACCOUNT_SUCCESS = "SWITCH_USER_ACCOUNT_SUCCESS";
export const SWITCH_USER_ACCOUNT_FAIL = "SWITCH_USER_ACCOUNT_FAIL";
